/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import * as auth from "../modules/auth/redux/AuthRedux";
import {useDispatch} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link, useLocation} from "react-router-dom";
import {MenuTwo} from "../components/MenuTwo";

const CustomerStudyDes = () => {
  const dispatch = useDispatch()
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth
  const location = useLocation();
  const locationState = location.state
  const videoURL = locationState.videoURL
  const mainImageURL = locationState.mainImageURL
  const desImageURL = locationState.desImageURL

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          height: '100%',
          margin: 'auto',
          backgroundColor: '#fff',
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100px',
            paddingTop: '20px',
            paddingLeft: '20px',
            backgroundColor: '#ff9000'
          }}
        >
          <Link to={{
            pathname: '/customer/study',
          }}>
            {/*anh chinh an vao de xem video khoa hoc*/}
            <img style={{ width: '20px', height: '23px' }} src={toAbsoluteUrl('/media/logos/back.png')} alt=""/>
          </Link>
        </div>

        <div
          style={{
            marginTop: '-50px',
            textAlign: 'center'
          }}
        >
          <Link to={{
            pathname: '/customer/report-video',
            state: { videoURL: videoURL }
          }}>
            {/*anh chinh an vao de xem video khoa hoc*/}
            <img style={{ width: '100%', height: 'auto', padding: '15px', borderRadius: '30px' }} src={mainImageURL} alt=""/>
          </Link>
        </div>

        {/*anh mo ta*/}
        <img style={{ width: '100%', height: 'auto', padding: '0px', borderRadius: '30px' }} src={desImageURL} alt=""/>




        <MenuTwo active='study'/>
      </div>

    </div>  )
}

export {CustomerStudyDes}
