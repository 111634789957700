/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import axios from "axios";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import {useHistory, Link} from 'react-router-dom'
import {BACKEND_URL} from "../../modules/auth/redux/AuthCRUD";

type Props = {
  className: string
}

const requestShareholders = (accessToken: any) => {
  return axios.post(`${BACKEND_URL}/shareholder/approve-quantity`, {
    access_token: accessToken
  }).then(data => data.data)
}

const TableShareholderApproveQuantity: React.FC<Props> = ({className}) => {
  const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual)
  const [shareholders, setShareholders] = useState([])
  const history = useHistory()

  useEffect(() => {
    // lay danh sach co dong
    requestShareholders(accessToken).then(resData => {
      if (resData.error_code === 1) {
        console.log(resData.data)
        setShareholders(resData.data.shareholders)
      }
    })
    return () => {}
  }, [])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Danh sách số lượng tài khoản chờ duyệt</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='min-w-150px'>Tên trưởng bộ phận</th>
                <th className='min-w-140px'>Số điện thoại</th>
                <th className='min-w-120px'>Số lượng TK cần duyệt</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {shareholders.map((shareholder: any) => {
                return (
                  <tr key={shareholder.id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                            {shareholder.user.name ?? ''}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                        {shareholder.user.phone ?? ''}
                      </a>
                    </td>
                    <td className='text-end'>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2'>
                          <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                            {shareholder.account_quantity ?? 0}
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {TableShareholderApproveQuantity}
