/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import axios from "axios";
import {shallowEqual, useSelector} from "react-redux";
import {useHistory, Link} from 'react-router-dom'
import {BACKEND_URL} from "../../modules/auth/redux/AuthCRUD";
import {PageTitle} from "../../../_metronic/layout/core";
import {useIntl} from "react-intl";

const TableAgencyByAdmin = () => {
  const accessToken = useSelector(({auth}) => auth.accessToken, shallowEqual)
  const [agencies, setAgencies] = useState([])
  const history = useHistory()
  const intl = useIntl()

  useEffect(() => {
    axios.post(`${BACKEND_URL}/agency/all`, {
      access_token: accessToken
    }).then(data => data.data)
      .then(resData => {
        if (resData.error_code === 1) {
          setAgencies(resData.data.agencies)
        }
      })
    return () => {}
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.All_AGENCY'})}</PageTitle>
      <div className='row gy-5 gx-xl-8'>
        <div className='col-xl-12'>
          <div className={`card card-xxl-stretch mb-5 mb-xl-8`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Danh sách trưởng nhóm</span>
              </h3>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3'>
              {/* begin::Table container */}
              <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  {/* begin::Table head */}
                  <thead>
                  <tr className='fw-bolder text-muted'>
                    <th className='min-w-150px'>Tên trưởng nhóm</th>
                    <th className='min-w-120px'>Định danh trưởng nhóm</th>
                    <th className='min-w-140px'>Số điện thoại</th>
                  </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                  {agencies.map((agency) => {
                    const accountQuantityArr = agency.agency_account_quantity
                    return (
                      <tr key={agency.id}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                        <span className='text-dark fw-bolder text-hover-primary fs-6'>
                          {agency.name ?? ''}
                        </span>
                            </div>
                          </div>
                        </td>
                        <td>
                    <span className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                      {agency.alias ?? ''}
                    </span>
                        </td>
                        <td>
                    <span className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                      {agency.phone ?? ''}
                    </span>
                        </td>
                      </tr>
                    )
                  })}
                  </tbody>
                  {/* end::Table body */}
                </table>
                {/* end::Table */}
              </div>
              {/* end::Table container */}
            </div>
            {/* begin::Body */}
          </div>
        </div>
      </div>
    </>
  )
}

export {TableAgencyByAdmin}
