import React, {useState} from 'react'
import {shallowEqual, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link, useHistory} from "react-router-dom";
import {MenuTwo} from "../components/MenuTwo";

const CustomerPackage = () => {
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth
  const history = useHistory()

  const currentUser = useSelector(({auth}) => auth.user, shallowEqual)
  const [packageProduct, setPackageProduct] = useState('');

  return (
    <>
      <div style={{ backgroundColor: '#333', height: '100%'}}>
        <div
          style={{
            width: WIDTH,
            height: '100%',
            margin: 'auto',
            backgroundColor: '#fff'
          }}
        >
          <div
            style={{
              width: '100%',
              padding: '30px'
            }}
          >

            <p
              style={{
                color: '#2abf00',
                fontSize: '32px',
                fontFamily: 'Linotte-Heavy'
              }}
            >
              CÁC GÓI
            </p>
            <p
              style={{
                color: '#006dff',
                fontSize: '42px',
                fontFamily: 'Linotte-Heavy',
                marginTop: '-20px'
              }}
            >
              SẢN PHẨM
            </p>
            <p
              style={{
                color: '#006dff',
                fontSize: '42px',
                fontFamily: 'Linotte-Heavy',
                marginTop: '-25px'
              }}
            >
              TIẾP THEO
            </p>


            <div
              onClick={() => {setPackageProduct("PACKAGE_ONE")}}
              style={{
                display: currentUser.user.level === 0 ? 'block' : 'none',
                width: '100%',
                border: '2px solid #ddd',
                borderRadius: '10px',
                paddingLeft: '15px',
                paddingRight: '15px',
                fontSize: '22px',
                fontWeight: 'bolder',
                color: packageProduct === "PACKAGE_ONE" ? 'white' : '#bebebe',
                textAlign: 'left',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '10px',
                marginTop: '5%',
                backgroundColor: packageProduct === "PACKAGE_ONE" ? '#787878' : '#f5f5f5',
                fontFamily: 'Linotte-Heavy'
              }}
            >
              Gói 1: Báo cáo phân tích tính cách bằng camera trí tuệ nhân tạo: 100.000đ/ lần.
            </div>

            <div
              onClick={() => {setPackageProduct("PACKAGE_TWO")}}
              style={{
                display: currentUser.user.level === 1 ? 'block' : 'none',
                width: '100%',
                border: '2px solid #ddd',
                borderRadius: '10px',
                paddingLeft: '15px',
                paddingRight: '15px',
                fontSize: '22px',
                fontWeight: 'bolder',
                color: packageProduct === "PACKAGE_TWO" ? 'white' : '#bebebe',
                textAlign: 'left',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '10px',
                marginTop: '5%',
                backgroundColor: packageProduct === "PACKAGE_TWO" ? '#787878' : '#f5f5f5',
                fontFamily: 'Linotte-Heavy'
              }}
            >
              Gói 2: Báo cáo định hướng nghề nghiệp và khóa học tư duy hướng nghiệp: 200.000đ/ lần.
            </div>

            <div
              onClick={() => {setPackageProduct("PACKAGE_THREE")}}
              style={{
                display: currentUser.user.level !== 3 ? 'block' : 'none',
                width: '100%',
                border: '2px solid #ddd',
                borderRadius: '10px',
                paddingLeft: '15px',
                paddingRight: '15px',
                fontSize: '22px',
                fontWeight: 'bolder',
                color: packageProduct === "PACKAGE_THREE" ? 'white' : '#bebebe',
                textAlign: 'left',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '10px',
                marginTop: '5%',
                backgroundColor: packageProduct === "PACKAGE_THREE" ? '#787878' : '#f5f5f5',
                fontFamily: 'Linotte-Heavy'
              }}
            >
              Gói 3: Bản báo cáo đầy đủ 12 thông tin chi tiết về tính cách, bản đồ giao tiếp trong gia đình và các khóa học online tặng kèm: 1.600.000đ/ bản.
            </div>

            <div
              style={{
                height: '60px',
              }}
            >
            </div>

            <button
              disabled={ packageProduct ? '' : 'disabled' }
              type="button"
              style={{
                width: '100%',
                padding: '15px',
                backgroundColor: packageProduct ? '#30a3fc' : '#a1e6b9',
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
                borderRadius: '5px',
                borderWidth: '0px'
              }}
              onClick={() => {
                if (packageProduct === 'PACKAGE_ONE') {
                  history.push('/customer/package-one')
                } else if (packageProduct === 'PACKAGE_TWO') {
                  history.push('/customer/package-two')
                } else if (packageProduct === 'PACKAGE_THREE') {
                  history.push('/customer/package-three')
                }
              }}
            >
            <span
              style={{
                fontSize: '24px',
                fontWeight: 'bolder',
                color: '#fff',
                letterSpacing: 2.0,
                fontFamily: 'Linotte-Regular'
              }}
            >
              XÁC NHẬN
            </span>
            </button>

          </div>

          <MenuTwo active='study'/>
        </div>

      </div>
    </>
  )

}

export {CustomerPackage}
