import React from 'react'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link} from "react-router-dom";

const CustomerReport = () => {
  const dispatch = useDispatch()
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth
  const currentUser = useSelector(({auth}) => auth.user, shallowEqual)
  const userInfo = useSelector(store => store.userInfo)
  const LANGUAGE = userInfo.language

  // báo cáo người lớn - trẻ con
  const isChild = currentUser.user.is_child;

  // đã trả tiền được đại lý duyệt
  const isActive = currentUser.user.is_active;

  // đã quét mặt
  const isScan = currentUser.user.is_scan;

  return (
    <>
      {isActive && isScan
        ? (
          // da kich hoat
          <>
            {isChild
              ? (
                // tre em
                <>
                  <div style={{ backgroundColor: '#333', height: '100%'}}>
                    <div
                      style={{
                        width: WIDTH,
                        height: '100%',
                        margin: 'auto',
                        backgroundColor: '#fff'
                      }}
                    >
                      <div
                        style={{
                          width: '100%',
                          height: '100px',
                          paddingTop: '30px',
                          paddingLeft: '20px',
                          paddingRight: '20px',
                          backgroundColor: '#ff9000',
                          justifyContent: 'space-between',
                          display: 'flex'
                        }}
                      >
                        <h1
                          style={{
                            color: 'white',
                            display: 'inline-block',
                            fontFamily: 'Linotte-Heavy',
                            fontSize: '24px'
                          }}
                        >
                          {LANGUAGE === 'VI'
                            ? 'Báo cáo'
                            : 'Report'
                          }
                        </h1>
                        <div>
                          <h1
                            onClick={() => dispatch({
                              type: 'SET_LANGUAGE',
                              payload: {
                                language: 'VI'
                              }
                            })}
                            style={{color: LANGUAGE === 'VI' ? 'white' : '#ccc', display: 'inline-block'}}>
                            VIE
                          </h1>
                          <h1 style={{color: '#ccc', display: 'inline-block', marginLeft: '10px', marginRight: '10px'}}>
                            |
                          </h1>
                          <h1
                            onClick={() => dispatch({
                              type: 'SET_LANGUAGE',
                              payload: {
                                language: 'EN'
                              }
                            })}
                            style={{color: LANGUAGE === 'EN' ? 'white' : '#ccc', display: 'inline-block'}}>
                            ENG
                          </h1>
                        </div>

                      </div>

                      <div
                        style={{
                          marginTop: '-50px',
                          textAlign: 'center'
                        }}
                      >
                        <img style={{ width: '100%', height: 'auto', padding: '15px', borderRadius: '30px' }} src={currentUser.user.character.main_image_link} alt=""/>
                        <h2 style={{ marginTop: '-40px', color: '#fff' }}>{currentUser.user.name}</h2>
                        <div style={{ padding: '10px 40px', textAlign: 'center' }}>
                          <h4 style={{ color: '#01a1ff', lineHeight: '1.6' }} >"{
                            LANGUAGE === 'VI'
                              ? currentUser.user.character.description
                              : currentUser.user.character.description_en
                          }"</h4>
                        </div>
                      </div>


                      <Link to={{
                        pathname: '/customer/report-video',
                        state: {
                          videoURL: LANGUAGE === 'VI'
                            ? 'https://player.vimeo.com/video/' + currentUser.user.character.tong_quan.substring(18, 27) + '?autoplay=1'
                            : 'https://player.vimeo.com/video/' + currentUser.user.character.tong_quan_en.substring(18, 27) + '?autoplay=1'
                        }
                      }}>
                        <div
                          style={{
                            margin: '20px 15px',
                            padding: '15px',
                            position: 'relative',
                            border: '2px solid #dddddd',
                            borderRadius: '10px',
                            boxShadow: '0px 4px #dddddd',
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: 'Linotte-Heavy',
                            }}
                          >
                            {LANGUAGE === 'VI'
                              ? 'Tổng quan'
                              : 'Overview'
                            }
                          </h3>
                          <img style={{ position: 'absolute', right: '10px', bottom: '0px', width: '50px' }} src={toAbsoluteUrl('/media/logos/1-tong-quan.png')} alt=""/>
                        </div>
                      </Link>

                      <Link to={{
                        pathname: '/customer/report-video',
                        state: { videoURL: LANGUAGE === 'VI'
                          ? 'https://player.vimeo.com/video/' + currentUser.user.character.diem_manh.substring(18, 27) + '?autoplay=1'
                          : 'https://player.vimeo.com/video/' + currentUser.user.character.diem_manh_en.substring(18, 27) + '?autoplay=1'
                        }
                      }}>
                        <div
                          style={{
                            margin: '20px 15px',
                            padding: '15px',
                            position: 'relative',
                            border: '2px solid #dddddd',
                            borderRadius: '10px',
                            boxShadow: '0px 4px #dddddd',
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: 'Linotte-Heavy',
                            }}
                          >
                            {LANGUAGE === 'VI'
                              ? 'Điểm mạnh'
                              : 'Strength'
                            }
                          </h3>
                          <img style={{ position: 'absolute', right: '10px', bottom: '0px', width: '50px' }} src={toAbsoluteUrl('/media/logos/2-diem-manh.png')} alt=""/>
                        </div>
                      </Link>

                      <Link to={{
                        pathname: '/customer/report-video',
                        state: { videoURL: LANGUAGE === 'VI'
                            ? 'https://player.vimeo.com/video/' + currentUser.user.character.diem_yeu.substring(18, 27) + '?autoplay=1'
                            : 'https://player.vimeo.com/video/' + currentUser.user.character.diem_yeu_en.substring(18, 27) + '?autoplay=1'
                        }

                      }}>
                        <div
                          style={{
                            margin: '20px 15px',
                            padding: '15px',
                            position: 'relative',
                            border: '2px solid #dddddd',
                            borderRadius: '10px',
                            boxShadow: '0px 4px #dddddd',
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: 'Linotte-Heavy',
                            }}
                          >
                            {LANGUAGE === 'VI'
                              ? 'Điểm yếu'
                              : 'Weakness'
                            }
                          </h3>
                          <img style={{ position: 'absolute', right: '10px', bottom: '0px', width: '50px' }} src={toAbsoluteUrl('/media/logos/3-diem-yeu.png')} alt=""/>
                        </div>
                      </Link>

                      <Link to={{
                        pathname: '/customer/report-video',
                        state: { videoURL: LANGUAGE === 'VI'
                            ? 'https://player.vimeo.com/video/' + currentUser.user.character.nang_luc.substring(18, 27) + '?autoplay=1'
                            : 'https://player.vimeo.com/video/' + currentUser.user.character.nang_luc_en.substring(18, 27) + '?autoplay=1'
                        }

                      }}>
                        <div
                          style={{
                            margin: '20px 15px',
                            padding: '15px',
                            position: 'relative',
                            border: '2px solid #dddddd',
                            borderRadius: '10px',
                            boxShadow: '0px 4px #dddddd',
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: 'Linotte-Heavy',
                            }}
                          >
                            {LANGUAGE === 'VI'
                              ? 'Năng lực'
                              : 'Capacity'
                            }

                          </h3>
                          <img style={{ position: 'absolute', right: '10px', bottom: '0px', width: '50px' }} src={toAbsoluteUrl('/media/logos/4-nang-luc.png')} alt=""/>
                        </div>
                      </Link>

                      <Link to={{
                        pathname: '/customer/report-video',
                        state: { videoURL: LANGUAGE === 'VI'
                            ? 'https://player.vimeo.com/video/' + currentUser.user.character.bai_hoc.substring(18, 27) + '?autoplay=1'
                            : 'https://player.vimeo.com/video/' + currentUser.user.character.bai_hoc_en.substring(18, 27) + '?autoplay=1'
                        }

                      }}>
                        <div
                          style={{
                            margin: '20px 15px',
                            padding: '15px',
                            position: 'relative',
                            border: '2px solid #dddddd',
                            borderRadius: '10px',
                            boxShadow: '0px 4px #dddddd',
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: 'Linotte-Heavy',
                            }}
                          >
                            {LANGUAGE === 'VI'
                              ? 'Bài học'
                              : 'Lesson'
                            }
                          </h3>
                          <img style={{ position: 'absolute', right: '10px', bottom: '0px', width: '50px' }} src={toAbsoluteUrl('/media/logos/5-bai-hoc.png')} alt=""/>
                        </div>
                      </Link>

                      <Link to={{
                        pathname: '/customer/report-video',
                        state: { videoURL: LANGUAGE === 'VI'
                            ? 'https://player.vimeo.com/video/' + currentUser.user.character.moi_truong.substring(18, 27) + '?autoplay=1'
                            : 'https://player.vimeo.com/video/' + currentUser.user.character.moi_truong_en.substring(18, 27) + '?autoplay=1'
                        }

                      }}>
                        <div
                          style={{
                            margin: '20px 15px',
                            padding: '15px',
                            position: 'relative',
                            border: '2px solid #dddddd',
                            borderRadius: '10px',
                            boxShadow: '0px 4px #dddddd',
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: 'Linotte-Heavy',
                            }}
                          >
                            {LANGUAGE === 'VI'
                              ? 'Môi trường'
                              : 'Environment'
                            }
                          </h3>
                          <img style={{ position: 'absolute', right: '10px', bottom: '0px', width: '50px' }} src={toAbsoluteUrl('/media/logos/6-moi-truong.png')} alt=""/>
                        </div>
                      </Link>

                      <Link to={{
                        pathname: '/customer/report-video',
                        state: { videoURL: LANGUAGE === 'VI'
                            ? 'https://player.vimeo.com/video/' + currentUser.user.character.huong_nghiep.substring(18, 27) + '?autoplay=1'
                            : 'https://player.vimeo.com/video/' + currentUser.user.character.huong_nghiep_en.substring(18, 27) + '?autoplay=1'
                        }

                      }}>
                        <div
                          style={{
                            margin: '20px 15px',
                            padding: '15px',
                            position: 'relative',
                            border: '2px solid #dddddd',
                            borderRadius: '10px',
                            boxShadow: '0px 4px #dddddd',
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: 'Linotte-Heavy',
                            }}
                          >
                            {LANGUAGE === 'VI'
                              ? 'Hướng nghiệp'
                              : 'Vocational guidance'
                            }

                          </h3>
                          <img style={{ position: 'absolute', right: '10px', bottom: '0px', width: '50px' }} src={toAbsoluteUrl('/media/logos/7-huong-nghiep.png')} alt=""/>
                        </div>
                      </Link>

                      <Link to={{
                        pathname: '/customer/report-video',
                        state: { videoURL: LANGUAGE === 'VI'
                            ? 'https://player.vimeo.com/video/' + currentUser.user.character.ket_luan.substring(18, 27) + '?autoplay=1'
                            : 'https://player.vimeo.com/video/' + currentUser.user.character.ket_luan_en.substring(18, 27) + '?autoplay=1'
                        }

                      }}>
                        <div
                          style={{
                            margin: '20px 15px',
                            padding: '15px',
                            position: 'relative',
                            border: '2px solid #dddddd',
                            borderRadius: '10px',
                            boxShadow: '0px 4px #dddddd',
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: 'Linotte-Heavy',
                            }}
                          >
                            {LANGUAGE === 'VI'
                              ? 'Kết luận'
                              : 'Conclude'
                            }

                          </h3>
                          <img style={{ position: 'absolute', right: '10px', bottom: '0px', width: '50px' }} src={toAbsoluteUrl('/media/logos/8-ket-luan.png')} alt=""/>
                        </div>
                      </Link>

                      <div style={{ textAlign: 'center', padding: '15px' }}>
                        <h5 style={{ marginBottom: '15px', fontFamily: 'Linotte-Regular' }}>
                          {LANGUAGE === 'VI'
                            ? 'Xem báo cáo dạng văn bản'
                            : 'View text reports'
                          }

                        </h5>

                        <Link to={{
                          pathname: '/customer/report-image',
                          state: { imageURL: LANGUAGE === 'VI'
                            ? currentUser.user.character.report_image
                            : currentUser.user.character.report_image_en
                          }
                        }}>
                          <div style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#ff9000' }}>
                            <h4 style={{ color: '#fff', fontFamily: 'Linotte-Heavy' }}>
                              {LANGUAGE === 'VI'
                                ? 'Báo cáo'
                                : 'Report'
                              }

                            </h4>
                          </div>
                        </Link>
                      </div>



                      {/*height menu*/}
                      <div style={{height: '70px'}}></div>
                      {/*menu*/}
                      <div style={{
                        position: 'fixed',
                        bottom: 0,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: WIDTH,
                        height: '70px',
                        margin: 'auto'
                      }}>
                        <div style={{
                          width: '25%',
                          height: '100%',
                          backgroundColor: '#fff',
                          display: 'inline-block',
                          textAlign: 'center',
                          paddingTop: '10px'
                        }}>
                          <Link to='/customer/report-image'>
                            <div style={{padding: '0px 13px'}}>
                              <div style={{border: '1px solid #70cfff', borderRadius: '10px', backgroundColor: '#ade0fd'}}>
                                <img
                                  alt='Logo'
                                  className='h-50px'
                                  src={toAbsoluteUrl('/media/logos/1-bao-cao.png')}
                                />
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div style={{
                          width: '25%',
                          height: '100%',
                          backgroundColor: '#fff',
                          display: 'inline-block',
                          textAlign: 'center',
                          paddingTop: '10px'
                        }}>
                          <Link to='/customer/list-study'>
                            <img
                              alt='Logo'
                              className='h-50px'
                              src={toAbsoluteUrl('/media/logos/2-khoa-hoc.png')}
                            />
                          </Link>
                        </div>
                        <div style={{
                          width: '25%',
                          height: '100%',
                          backgroundColor: '#fff',
                          display: 'inline-block',
                          textAlign: 'center',
                          paddingTop: '10px'
                        }}>
                          <Link to='/customer/intro'>
                            <img
                              alt='Logo'
                              className='h-50px'
                              src={toAbsoluteUrl('/media/logos/3-gioi-thieu.png')}
                            />
                          </Link>
                        </div>
                        <div style={{
                          width: '25%',
                          height: '100%',
                          backgroundColor: '#fff',
                          display: 'inline-block',
                          textAlign: 'center',
                          paddingTop: '10px'
                        }}>
                          <Link to='/customer/info'>
                            <img
                              alt='Logo'
                              className='h-50px'
                              src={toAbsoluteUrl('/media/logos/4-tai-khoan.png')}
                            />
                          </Link>
                        </div>
                      </div>
                    </div>

                  </div>
                </>
              )
              : (
                // nguoi lon
                <>
                  <div style={{ backgroundColor: '#333', height: '100%'}}>
                    <div
                      style={{
                        width: WIDTH,
                        height: '100%',
                        margin: 'auto',
                        backgroundColor: '#fff'
                      }}
                    >
                      <div
                        style={{
                          width: '100%',
                          height: '100px',
                          paddingTop: '30px',
                          paddingLeft: '20px',
                          paddingRight: '20px',
                          backgroundColor: '#ff9000',
                          justifyContent: 'space-between',
                          display: 'flex'
                        }}
                      >
                        <h1
                          style={{
                            color: 'white',
                            display: 'inline-block',
                            fontFamily: 'Linotte-Heavy',
                            fontSize: '24px'
                          }}
                        >
                          {LANGUAGE === 'VI'
                            ? 'Báo cáo'
                            : 'Report'
                          }
                        </h1>
                        <div>
                          <h1
                            onClick={() => dispatch({
                              type: 'SET_LANGUAGE',
                              payload: {
                                language: 'VI'
                              }
                            })}
                            style={{color: LANGUAGE === 'VI' ? 'white' : '#ccc', display: 'inline-block'}}>
                            VIE
                          </h1>
                          <h1 style={{color: '#ccc', display: 'inline-block', marginLeft: '10px', marginRight: '10px'}}>
                            |
                          </h1>
                          <h1
                            onClick={() => dispatch({
                              type: 'SET_LANGUAGE',
                              payload: {
                                language: 'EN'
                              }
                            })}
                            style={{color: LANGUAGE === 'EN' ? 'white' : '#ccc', display: 'inline-block'}}>
                            ENG
                          </h1>
                        </div>

                      </div>

                      <div
                        style={{
                          marginTop: '-50px',
                          textAlign: 'center'
                        }}
                      >
                        <img style={{ width: '100%', height: 'auto', padding: '15px', borderRadius: '30px' }} src={currentUser.user.character_adult.main_image_link} alt=""/>
                        <h2 style={{ marginTop: '-40px', color: '#fff' }}>{currentUser.user.name}</h2>
                        <div style={{ padding: '10px 40px', textAlign: 'center' }}>
                          <h4 style={{ color: '#01a1ff', lineHeight: '1.6' }} >
                            "{
                            LANGUAGE === 'VI'
                              ? currentUser.user.character_adult.description
                              : currentUser.user.character_adult.description_en
                          }"
                          </h4>
                        </div>
                      </div>

                      <Link to={{
                        pathname: '/customer/report-video',
                        state: { videoURL: LANGUAGE === 'VI'
                            ? 'https://player.vimeo.com/video/' + currentUser.user.character_adult.cot_loi.substring(18, 27) + '?autoplay=1'
                            : 'https://player.vimeo.com/video/' + currentUser.user.character_adult.cot_loi_en.substring(18, 27) + '?autoplay=1'
                        }
                      }}>
                        <div
                          style={{
                            margin: '20px 15px',
                            padding: '15px',
                            position: 'relative',
                            border: '2px solid #dddddd',
                            borderRadius: '10px',
                            boxShadow: '0px 4px #dddddd',
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: 'Linotte-Heavy',
                            }}
                          >
                            {LANGUAGE === 'VI'
                              ? 'Cốt lõi'
                              : 'Core'
                            }

                          </h3>
                          <img style={{ position: 'absolute', right: '10px', bottom: '0px', width: '50px' }} src={toAbsoluteUrl('/media/logos/cot-loi.png')} alt=""/>
                        </div>
                      </Link>


                      <Link to={{
                        pathname: '/customer/report-video',
                        state: { videoURL: LANGUAGE === 'VI'
                            ? 'https://player.vimeo.com/video/' + currentUser.user.character_adult.tong_quan.substring(18, 27) + '?autoplay=1'
                            : 'https://player.vimeo.com/video/' + currentUser.user.character_adult.tong_quan_en.substring(18, 27) + '?autoplay=1'
                        }
                      }}>
                        <div
                          style={{
                            margin: '20px 15px',
                            padding: '15px',
                            position: 'relative',
                            border: '2px solid #dddddd',
                            borderRadius: '10px',
                            boxShadow: '0px 4px #dddddd',
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: 'Linotte-Heavy',
                            }}
                          >
                            {LANGUAGE === 'VI'
                              ? 'Tổng quan'
                              : 'Overview'
                            }
                          </h3>
                          <img style={{ position: 'absolute', right: '10px', bottom: '0px', width: '50px' }} src={toAbsoluteUrl('/media/logos/1-tong-quan.png')} alt=""/>
                        </div>
                      </Link>

                      <Link to={{
                        pathname: '/customer/report-video',
                        state: { videoURL: LANGUAGE === 'VI'
                            ? 'https://player.vimeo.com/video/' + currentUser.user.character_adult.diem_manh.substring(18, 27) + '?autoplay=1'
                            : 'https://player.vimeo.com/video/' + currentUser.user.character_adult.diem_manh_en.substring(18, 27) + '?autoplay=1'
                        }
                      }}>
                        <div
                          style={{
                            margin: '20px 15px',
                            padding: '15px',
                            position: 'relative',
                            border: '2px solid #dddddd',
                            borderRadius: '10px',
                            boxShadow: '0px 4px #dddddd',
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: 'Linotte-Heavy',
                            }}
                          >
                            {LANGUAGE === 'VI'
                              ? 'Điểm mạnh'
                              : 'Strength'
                            }
                          </h3>
                          <img style={{ position: 'absolute', right: '10px', bottom: '0px', width: '50px' }} src={toAbsoluteUrl('/media/logos/2-diem-manh.png')} alt=""/>
                        </div>
                      </Link>

                      <Link to={{
                        pathname: '/customer/report-video',
                        state: { videoURL: LANGUAGE === 'VI'
                            ? 'https://player.vimeo.com/video/' + currentUser.user.character_adult.diem_yeu.substring(18, 27) + '?autoplay=1'
                            : 'https://player.vimeo.com/video/' + currentUser.user.character_adult.diem_yeu_en.substring(18, 27) + '?autoplay=1'
                        }
                      }}>
                        <div
                          style={{
                            margin: '20px 15px',
                            padding: '15px',
                            position: 'relative',
                            border: '2px solid #dddddd',
                            borderRadius: '10px',
                            boxShadow: '0px 4px #dddddd',
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: 'Linotte-Heavy',
                            }}
                          >
                            {LANGUAGE === 'VI'
                              ? 'Điểm yếu'
                              : 'Weakness'
                            }
                          </h3>
                          <img style={{ position: 'absolute', right: '10px', bottom: '0px', width: '50px' }} src={toAbsoluteUrl('/media/logos/3-diem-yeu.png')} alt=""/>
                        </div>
                      </Link>

                      <Link to={{
                        pathname: '/customer/report-video',
                        state: { videoURL: LANGUAGE === 'VI'
                            ? 'https://player.vimeo.com/video/' + currentUser.user.character_adult.nang_luc.substring(18, 27) + '?autoplay=1'
                            : 'https://player.vimeo.com/video/' + currentUser.user.character_adult.nang_luc_en.substring(18, 27) + '?autoplay=1'
                        }
                      }}>
                        <div
                          style={{
                            margin: '20px 15px',
                            padding: '15px',
                            position: 'relative',
                            border: '2px solid #dddddd',
                            borderRadius: '10px',
                            boxShadow: '0px 4px #dddddd',
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: 'Linotte-Heavy',
                            }}
                          >
                            {LANGUAGE === 'VI'
                              ? 'Năng lực'
                              : 'Capacity'
                            }
                          </h3>
                          <img style={{ position: 'absolute', right: '10px', bottom: '0px', width: '50px' }} src={toAbsoluteUrl('/media/logos/4-nang-luc.png')} alt=""/>
                        </div>
                      </Link>

                      <Link to={{
                        pathname: '/customer/report-video',
                        state: { videoURL: LANGUAGE === 'VI'
                            ? 'https://player.vimeo.com/video/' + currentUser.user.character_adult.bai_hoc.substring(18, 27) + '?autoplay=1'
                            : 'https://player.vimeo.com/video/' + currentUser.user.character_adult.bai_hoc_en.substring(18, 27) + '?autoplay=1'
                        }
                      }}>
                        <div
                          style={{
                            margin: '20px 15px',
                            padding: '15px',
                            position: 'relative',
                            border: '2px solid #dddddd',
                            borderRadius: '10px',
                            boxShadow: '0px 4px #dddddd',
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: 'Linotte-Heavy',
                            }}
                          >
                            {LANGUAGE === 'VI'
                              ? 'Bài học'
                              : 'Lesson'
                            }
                          </h3>
                          <img style={{ position: 'absolute', right: '10px', bottom: '0px', width: '50px' }} src={toAbsoluteUrl('/media/logos/5-bai-hoc.png')} alt=""/>
                        </div>
                      </Link>

                      <Link to={{
                        pathname: '/customer/report-video',
                        state: { videoURL: LANGUAGE === 'VI'
                            ? 'https://player.vimeo.com/video/' + currentUser.user.character_adult.moi_truong.substring(18, 27) + '?autoplay=1'
                            : 'https://player.vimeo.com/video/' + currentUser.user.character_adult.moi_truong_en.substring(18, 27) + '?autoplay=1'
                        }
                      }}>
                        <div
                          style={{
                            margin: '20px 15px',
                            padding: '15px',
                            position: 'relative',
                            border: '2px solid #dddddd',
                            borderRadius: '10px',
                            boxShadow: '0px 4px #dddddd',
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: 'Linotte-Heavy',
                            }}
                          >
                            {LANGUAGE === 'VI'
                              ? 'Môi trường làm việc'
                              : 'Work environment'
                            }
                          </h3>
                          <img style={{ position: 'absolute', right: '10px', bottom: '0px', width: '50px' }} src={toAbsoluteUrl('/media/logos/6-moi-truong.png')} alt=""/>
                        </div>
                      </Link>

                      <Link to={{
                        pathname: '/customer/report-video',
                        state: { videoURL: LANGUAGE === 'VI'
                            ? 'https://player.vimeo.com/video/' + currentUser.user.character_adult.huong_nghiep.substring(18, 27) + '?autoplay=1'
                            : 'https://player.vimeo.com/video/' + currentUser.user.character_adult.huong_nghiep_en.substring(18, 27) + '?autoplay=1'
                        }
                      }}>
                        <div
                          style={{
                            margin: '20px 15px',
                            padding: '15px',
                            position: 'relative',
                            border: '2px solid #dddddd',
                            borderRadius: '10px',
                            boxShadow: '0px 4px #dddddd',
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: 'Linotte-Heavy',
                            }}
                          >
                            {LANGUAGE === 'VI'
                              ? 'Hướng nghiệp'
                              : 'Vocational guidance'
                            }
                          </h3>
                          <img style={{ position: 'absolute', right: '10px', bottom: '0px', width: '50px' }} src={toAbsoluteUrl('/media/logos/7-huong-nghiep.png')} alt=""/>
                        </div>
                      </Link>

                      <Link to={{
                        pathname: '/customer/report-video',
                        state: { videoURL: LANGUAGE === 'VI'
                            ? 'https://player.vimeo.com/video/' + currentUser.user.character_adult.ket_luan.substring(18, 27) + '?autoplay=1'
                            : 'https://player.vimeo.com/video/' + currentUser.user.character_adult.ket_luan_en.substring(18, 27) + '?autoplay=1'
                        }
                      }}>
                        <div
                          style={{
                            margin: '20px 15px',
                            padding: '15px',
                            position: 'relative',
                            border: '2px solid #dddddd',
                            borderRadius: '10px',
                            boxShadow: '0px 4px #dddddd',
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: 'Linotte-Heavy',
                            }}
                          >
                            {LANGUAGE === 'VI'
                              ? 'Kết luận'
                              : 'Conclude'
                            }
                          </h3>
                          <img style={{ position: 'absolute', right: '10px', bottom: '0px', width: '50px' }} src={toAbsoluteUrl('/media/logos/8-ket-luan.png')} alt=""/>
                        </div>
                      </Link>

                      <div style={{ textAlign: 'center', padding: '15px' }}>
                        <h5 style={{ marginBottom: '15px', fontFamily: 'Linotte-Regular' }}>
                          {LANGUAGE === 'VI'
                            ? 'Xem báo cáo dạng văn bản'
                            : 'View text reports'
                          }
                        </h5>

                        <Link to={{
                          pathname: '/customer/report-image',
                          state: { imageURL: LANGUAGE === 'VI'
                              ? currentUser.user.character_adult.report_image
                              : currentUser.user.character_adult.report_image_en
                          }
                        }}>
                          <div style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#ff9000' }}>
                            <h4
                              style={{
                                color: '#fff',
                                fontFamily: 'Linotte-Heavy'
                              }}
                            >
                              {LANGUAGE === 'VI'
                                ? 'Báo cáo'
                                : 'Report'
                              }
                            </h4>
                          </div>
                        </Link>
                      </div>











                      {/*height menu*/}
                      <div style={{height: '70px'}}></div>
                      {/*menu*/}
                      <div style={{
                        position: 'fixed',
                        bottom: 0,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: WIDTH,
                        height: '70px',
                        margin: 'auto'
                      }}>
                        <div style={{
                          width: '25%',
                          height: '100%',
                          backgroundColor: '#fff',
                          display: 'inline-block',
                          textAlign: 'center',
                          paddingTop: '10px'
                        }}>
                          <Link to='/customer/report-image'>
                            <div style={{padding: '0px 13px'}}>
                              <div style={{border: '1px solid #70cfff', borderRadius: '10px', backgroundColor: '#ade0fd'}}>
                                <img
                                  alt='Logo'
                                  className='h-50px'
                                  src={toAbsoluteUrl('/media/logos/1-bao-cao.png')}
                                />
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div style={{
                          width: '25%',
                          height: '100%',
                          backgroundColor: '#fff',
                          display: 'inline-block',
                          textAlign: 'center',
                          paddingTop: '10px'
                        }}>
                          <Link to='/customer/list-study'>
                            <img
                              alt='Logo'
                              className='h-50px'
                              src={toAbsoluteUrl('/media/logos/2-khoa-hoc.png')}
                            />
                          </Link>
                        </div>
                        <div style={{
                          width: '25%',
                          height: '100%',
                          backgroundColor: '#fff',
                          display: 'inline-block',
                          textAlign: 'center',
                          paddingTop: '10px'
                        }}>
                          <Link to='/customer/intro'>
                            <img
                              alt='Logo'
                              className='h-50px'
                              src={toAbsoluteUrl('/media/logos/3-gioi-thieu.png')}
                            />
                          </Link>
                        </div>
                        <div style={{
                          width: '25%',
                          height: '100%',
                          backgroundColor: '#fff',
                          display: 'inline-block',
                          textAlign: 'center',
                          paddingTop: '10px'
                        }}>
                          <Link to='/customer/info'>
                            <img
                              alt='Logo'
                              className='h-50px'
                              src={toAbsoluteUrl('/media/logos/4-tai-khoan.png')}
                            />
                          </Link>
                        </div>
                      </div>
                    </div>

                  </div>
                </>
              )
            }
          </>
        )
        : (
          // chua kich hoat
          <>
            <div style={{ backgroundColor: '#333', height: '100%'}}>
              <div
                style={{
                  width: WIDTH,
                  height: '100%',
                  margin: 'auto',
                  backgroundColor: '#fff'
                }}
              >
                <img style={{ width: '100%', height: window.innerHeight - 70}} src={toAbsoluteUrl('/media/logos/inactive-report3.jpg')} alt=""/>



                {/*height menu*/}
                <div style={{height: '70px'}}></div>
                {/*menu*/}
                <div style={{
                  position: 'fixed',
                  bottom: 0,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  width: WIDTH,
                  height: '70px',
                  margin: 'auto'
                }}>
                  <div style={{
                    width: '25%',
                    height: '100%',
                    backgroundColor: '#fff',
                    display: 'inline-block',
                    textAlign: 'center',
                    paddingTop: '10px'
                  }}>
                    <Link to='/customer/report-image'>
                      <div style={{padding: '0px 13px'}}>
                        <div style={{border: '1px solid #70cfff', borderRadius: '10px', backgroundColor: '#ade0fd'}}>
                          <img
                            alt='Logo'
                            className='h-50px'
                            src={toAbsoluteUrl('/media/logos/1-bao-cao.png')}
                          />
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div style={{
                    width: '25%',
                    height: '100%',
                    backgroundColor: '#fff',
                    display: 'inline-block',
                    textAlign: 'center',
                    paddingTop: '10px'
                  }}>
                    <Link to='/customer/list-study'>
                      <img
                        alt='Logo'
                        className='h-50px'
                        src={toAbsoluteUrl('/media/logos/2-khoa-hoc.png')}
                      />
                    </Link>
                  </div>
                  <div style={{
                    width: '25%',
                    height: '100%',
                    backgroundColor: '#fff',
                    display: 'inline-block',
                    textAlign: 'center',
                    paddingTop: '10px'
                  }}>
                    <Link to='/customer/intro'>
                      <img
                        alt='Logo'
                        className='h-50px'
                        src={toAbsoluteUrl('/media/logos/3-gioi-thieu.png')}
                      />
                    </Link>
                  </div>
                  <div style={{
                    width: '25%',
                    height: '100%',
                    backgroundColor: '#fff',
                    display: 'inline-block',
                    textAlign: 'center',
                    paddingTop: '10px'
                  }}>
                    <Link to='/customer/info'>
                      <img
                        alt='Logo'
                        className='h-50px'
                        src={toAbsoluteUrl('/media/logos/4-tai-khoan.png')}
                      />
                    </Link>
                  </div>
                </div>
              </div>

            </div>
          </>
        )
      }

    </>
  )

}

export {CustomerReport}
