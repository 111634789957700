/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import * as auth from "../modules/auth/redux/AuthRedux";
import {useDispatch} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link, useParams, useLocation} from "react-router-dom";
import {MenuTwo} from "../components/MenuTwo";

const CustomerReportVideo = () => {
  const dispatch = useDispatch()
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth
  const location = useLocation();
  const videoURL = location.state.videoURL

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          height: '100%',
          margin: 'auto',
          backgroundColor: '#fff'
        }}
      >
        <iframe
          style={{ width: '100%', height: '100%', paddingBottom: '70px' }}
          src={videoURL}
          frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"
        />

        <script src="https://player.vimeo.com/api/player.js"></script>

        <MenuTwo active='study'/>
      </div>
    </div>
  )
}

export {CustomerReportVideo}
