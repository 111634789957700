/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link, useHistory} from "react-router-dom";
import axios from "axios";
import {BACKEND_URL} from "../modules/auth/redux/AuthCRUD";
import {MenuTwo} from "../components/MenuTwo";

const UpgradeGlobal = () => {
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const currentUser = useSelector(({auth}) => auth.user, shallowEqual)
  const userInfo = useSelector(store => store.userInfo)
  const LANGUAGE = userInfo.language
  const isActive = currentUser.user.is_active;
  const isScan = currentUser.user.is_scan;
  const isChild = currentUser.user.is_child;
  const history = useHistory()


  const [oldPassword, setOldPassword] = useState('old_password')
  const [newPassword, setNewPassword] = useState('')
  const [secretCode, setSecretCode] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const confirmChangePassword = () => {
    axios.post(`${BACKEND_URL}/customer/upgrade-global`, {
      access_token: currentUser.user.access_token,
      phone: currentUser.user.phone,
      code: secretCode,
    }).then(res => res.data)
      .then(resData => {
        if (resData.error_code === 1) {
          setErrorMessage('Nâng cấp bản đồ giao tiếp thành công')
          setTimeout(() => {
            history.push('/customer/report-image')
          }, 2000)
        } else {
          setErrorMessage(resData.message ?? 'Sai thông tin, vui lòng nhập lại')
        }
      })
  }

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          height: '100%',
          margin: 'auto',
          backgroundColor: '#fff'
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100px',
            paddingTop: '30px',
            paddingLeft: '20px',
            backgroundColor: '#a14ad9'
          }}
        >
          <h1 style={{color: 'white'}}>
            Nâng cấp bản đồ giao tiếp
          </h1>
        </div>

        <div
          style={{
            marginTop: '-50px',
            textAlign: 'center'
          }}
        >
          {isActive && isScan
            ?
            <img style={{width: '100%', height: 'auto', padding: '15px', borderRadius: '30px'}}
                 src={isChild ? currentUser.user.character.main_image_link : currentUser.user.character_adult.main_image_link} alt=""/>
            :
            <img style={{width: '100%', height: 'auto', padding: '15px', borderRadius: '30px'}}
                 src={toAbsoluteUrl('/media/logos/inactive-user.jpg')} alt=""/>
          }
          <h2 style={{ marginTop: '-40px', color: '#fff' }}>{currentUser.user.name}</h2>
          <div style={{ padding: '10px 40px', textAlign: 'center' }}>
            <h4 style={{ color: '#01a1ff', lineHeight: '1.8' }} >
              {LANGUAGE === 'VI'
                ? 'Số điện thoại: '
                : 'Phone number: '
              }
              {currentUser.user.phone}</h4>
          </div>
        </div>

        <div style={{ padding: '20px' }}>
          {errorMessage && <h3 className="text-danger mb-4">{errorMessage}</h3>}

          <div className="">
            <div className="form-group my-4">
              <h4>
                Mã giới thiệu từ Global
                <span className="text-danger">*</span></h4>
              <input
                value={secretCode}
                onChange={(e) => setSecretCode(e.target.value)}
                type="text" className="form-control" placeholder=""/>
            </div>
          </div>
        </div>


        <div style={{ padding: '10px' }}>
          <div
            onClick={() => {confirmChangePassword()}}
            style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#ff9000' }}>
            <h4 style={{ color: '#fff' }}>
              {LANGUAGE === 'VI'
                ? 'Xác nhận'
                : 'Confirm'
              }
            </h4>
          </div>
        </div>

        <MenuTwo active='report'/>
      </div>

    </div>
  )
}

export {UpgradeGlobal}

