/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {TableShareholder} from "./TableShareholder";

const ManageShareholder: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.MANAGE_SHAREHOLDER'})}</PageTitle>
      <div className='row gy-5 gx-xl-8'>
        <div className='col-xl-12'>
          <TableShareholder className='card-xxl-stretch mb-5 mb-xl-8' />
        </div>
      </div>
    </>
  )
}

export {ManageShareholder}
