/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {FC} from 'react'
import {KTSVG} from '../../../helpers'
import {useLayout} from '../../core'
import {DefaultTitle} from '../header/page-title/DefaultTitle'
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../../setup";

const Toolbar1: FC = () => {
  const {classes} = useLayout()
  const authInfo: any = useSelector<RootState>(({auth}) => auth, shallowEqual)
  let authRoleID = undefined
  if (authInfo) {
    authRoleID = authInfo.user.user.auth_role_id
  }

  return (
    <div className='toolbar' id='kt_toolbar'>
      {/* begin::Container */}
      <div
        id='kt_toolbar_container'
        className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
      >
        <DefaultTitle />

        {/* begin::Actions */}
        <div className='d-flex align-items-center py-1 w-100'>
          {/* begin::Wrapper */}
          <div className='me-4 w-100'>
            {/* begin::Menu */}

            {authRoleID === 1 &&
            <p className='fs-2 text-danger text-center'>TÀI KHOẢN ADMIN</p>
            }
            {authRoleID === 2 &&
            <p className='fs-2 text-danger text-center'>TÀI KHOẢN TRƯỞNG BỘ PHẬN</p>
            }
            {authRoleID === 3 &&
            <p className='fs-2 text-danger text-center'>TÀI KHOẢN TRƯỞNG NHÓM</p>
            }
            {authRoleID === 5 &&
            <p className='fs-2 text-danger text-center'>TÀI KHOẢN CỘNG TÁC VIÊN</p>
            }

            {/*<a*/}
            {/*  href='#'*/}
            {/*  className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder'*/}
            {/*  data-kt-menu-trigger='click'*/}
            {/*  data-kt-menu-placement='bottom-end'*/}
            {/*  data-kt-menu-flip='top-end'*/}
            {/*>*/}
            {/*  <KTSVG*/}
            {/*    path='/media/icons/duotone/Text/Filter.svg'*/}
            {/*    className='svg-icon-5 svg-icon-gray-500 me-1'*/}
            {/*  />*/}
            {/*  Filter*/}
            {/*</a>*/}

            {/* end::Menu */}
          </div>
          {/* end::Wrapper */}

          {/* begin::Button */}

          {/*<a*/}
          {/*  href='#'*/}
          {/*  className='btn btn-sm btn-primary'*/}
          {/*  data-bs-toggle='modal'*/}
          {/*  data-bs-target='#kt_modal_create_app'*/}
          {/*  id='kt_toolbar_primary_button'*/}
          {/*>*/}
          {/*  Create*/}
          {/*</a>*/}
          {/* end::Button */}
        </div>
        {/* end::Actions */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export {Toolbar1}
