import React, {useState} from 'react'
import {shallowEqual, useSelector, useDispatch} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link} from "react-router-dom";
import {MenuTwo} from "../components/MenuTwo";
import axios from "axios";
import {BACKEND_URL} from "../modules/auth/redux/AuthCRUD";

const Health = () => {
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const currentUser = useSelector(({auth}) => auth.user, shallowEqual)
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          height: '100%',
          margin: 'auto',
          backgroundColor: '#bbeafe'
        }}
      >
        <img style={{ width: '100%', height: 'auto', padding: '0px', borderRadius: '0px' }}
             src={toAbsoluteUrl('/media/logos/suc-khoe.jpg')}
             alt=""
        />
        <div
          style={{
            padding: '20px'
          }}
        >
          <div
            style={{
              width: '100%',
              margin: 'auto',
              backgroundColor: '#fff',
              borderRadius: '20px',
            }}
          >
            <div
              style={{
                backgroundColor: '#f2f2f2',
                padding: '20px',
                borderRadius: '20px',
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'inline-block'
                }}
              >

                <div
                  style={{
                    borderBottom: '1px dashed #b0b0b0',
                    margin: '5px',
                    padding: '10px 10px 10px 0px'
                  }}
                >
                  <p
                    style={{
                      color: '#000',
                      fontSize: '20px',
                      fontFamily: 'Linotte-Regular',
                      textAlign: 'center'
                    }}
                  >
                    Mời bạn chuyển khoản về tài khoản để hoàn tất đăng ký mua sớm:
                  </p>
                </div>

                <div
                  style={{
                    borderBottom: '1px dashed #b0b0b0',
                    margin: '5px',
                    padding: '10px 10px 10px 0px'
                  }}
                >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '20px',
                                  fontFamily: 'Linotte-Regular'
                                }}
                              >
                                {'Ngân hàng: '}
                              </span>
                  <span
                    style={{
                      color: '#fd1a1c',
                      fontSize: '20px',
                      fontFamily: 'Linotte-Regular'
                    }}
                  >
                                Quân đội MBBank
                              </span>
                </div>

                <div
                  style={{
                    borderBottom: '1px dashed #b0b0b0',
                    margin: '5px',
                    padding: '10px 10px 10px 0px'
                  }}
                >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '20px',
                                  fontFamily: 'Linotte-Regular'
                                }}
                              >
                                {'Số tài khoản: '}
                              </span>
                  <span
                    style={{
                      color: '#fd1a1c',
                      fontSize: '20px',
                      fontFamily: 'Linotte-Regular'
                    }}
                  >
                                1988288288
                              </span>
                  <br/>
                  <button className='btn btn-success btn-sm ms-4'
                          style={{ marginTop: '0px' }}
                          onClick={() => navigator.clipboard.writeText('1988288288')}
                  >
                    Copy
                  </button>
                </div>

                <div
                  style={{
                    borderBottom: '1px dashed #b0b0b0',
                    margin: '5px',
                    padding: '10px 10px 10px 0px'
                  }}
                >
                <span
                  style={{
                    color: '#333',
                    fontSize: '20px',
                    fontFamily: 'Linotte-Regular'
                  }}
                >
                  {'Tên tài khoản: '}
                </span>
                  <span
                    style={{
                      color: '#fd1a1c',
                      fontSize: '20px',
                      fontFamily: 'Linotte-Regular'
                    }}
                  >
                  Trần Ngọc Thiện
                </span>
                </div>


                <div
                  style={{
                    borderBottom: '1px dashed #b0b0b0',
                    margin: '5px',
                    padding: '10px 10px 10px 0px'
                  }}
                >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '20px',
                                  fontFamily: 'Linotte-Regular'
                                }}
                              >
                                {'Số tiền: '}
                              </span>
                  <span
                    style={{
                      color: '#fd1a1c',
                      fontSize: '20px',
                      fontFamily: 'Linotte-Regular'
                    }}
                  >
                                1.000.000 VNĐ
                              </span>
                </div>

                <div
                  style={{
                    borderBottom: '1px dashed #b0b0b0',
                    margin: '5px',
                    padding: '10px 10px 10px 0px'
                  }}
                >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '20px',
                                  fontFamily: 'Linotte-Regular'
                                }}
                              >
                                {'Nội dung: '}
                              </span>
                  <span
                    style={{
                      color: '#fd1a1c',
                      fontSize: '20px',
                      fontFamily: 'Linotte-Regular'
                    }}
                  >
                                  {currentUser.user.phone}
                                </span>
                  <br/>
                  <button className='btn btn-success btn-sm ms-4'
                          style={{ marginTop: '0px' }}
                          onClick={() => navigator.clipboard.writeText(currentUser.user.phone)}
                  >
                    Copy
                  </button>
                </div>

                <div
                  style={{
                    margin: '5px',
                    padding: '10px 10px 10px 0px',
                    textAlign: 'center'
                  }}
                >
                <span
                  style={{
                    color: '#333',
                    fontSize: '22px',
                    fontFamily: 'Linotte-Regular'
                  }}
                >
                  {'Mã QR: '}
                </span>
                  <br/>
                  <div className='text-center'>
                    <img style={{ width: '90%', borderRadius: '5px', marginTop: '10px' }} src={toAbsoluteUrl('/media/logos/qr-tai-tro.jpg')} alt=""/>
                  </div>
                </div>

              </div>
            </div>



            {/*<MenuTwo active='study'/>*/}
          </div>
        </div>
        <MenuTwo active='health'/>
      </div>

    </div>
  )

}

export {Health}
