/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import * as auth from "../modules/auth/redux/AuthRedux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link} from "react-router-dom";
import {RootState} from "../../setup";
import {MenuTwo} from "../components/MenuTwo";

const CustomerUpgrade = () => {
  const dispatch = useDispatch()
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const currentUser = useSelector(({auth}) => auth.user, shallowEqual)
  const userInfo = useSelector(store => store.userInfo)
  const LANGUAGE = userInfo.language
  const isActive = currentUser.user.is_active;
  const isScan = currentUser.user.is_scan;
  const isCheck = currentUser.user.is_check;
  const isChild = currentUser.user.is_child;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          height: '100%',
          margin: 'auto',
          backgroundColor: '#fff'
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100px',
            paddingTop: '30px',
            paddingLeft: '20px',
            backgroundColor: '#a14ad9',
            paddingRight: '20px',
            justifyContent: 'space-between',
            display: 'flex'
          }}
        >
          <h1 style={{
            color: 'white',
            fontFamily: 'Linotte-Heavy',
            fontSize: '24px'
          }}>
           Upgrade Premium
          </h1>
        </div>

        <div
          style={{
            marginTop: '-50px',
            textAlign: 'center'
          }}
        >
          {isActive && isScan && isCheck
            ?
            <img style={{width: '100%', height: 'auto', padding: '15px', borderRadius: '30px'}}
                src={isChild ? currentUser.user.character.main_image_link : currentUser.user.character_adult.main_image_link} alt=""/>
            :
            <img style={{width: '100%', height: 'auto', padding: '15px', borderRadius: '30px'}}
                 src={toAbsoluteUrl('/media/logos/inactive-user.jpg')} alt=""/>
          }
          <h2 style={{ marginTop: '-40px', color: '#fff' }}>{currentUser.user.name}</h2>
          <div style={{ padding: '10px 40px', textAlign: 'center' }}>
            <h4 style={{ color: '#01a1ff', lineHeight: '1.8' }} >
              {LANGUAGE === 'VI'
                ? 'Số điện thoại: '
                : 'Phone number: '
              }
              {currentUser.user.phone}
            </h4>
          </div>
        </div>

        <div className='p-3'>
          <hr/>
          <p className='text-center text-success fs-3'>Please send 50 USD to Paypal or 50 USDT to Binance BEP-20 Address</p>
          <p className='text-center text-warning fs-3'>Paypal address</p>
          <p className='text-center text-success fs-3'><a href="https://paypal.me/idenperson" target='_blank'>https://paypal.me/idenperson</a></p>
          <hr/>
          <p className='text-center text-warning fs-3'>Binance BEP-20 USDT Address</p>
          <p className='text-center text-success fs-5'>0xbF95eeF713b595895922aB4cAb9805E1d58b910d</p>
          <div className='text-center'>
            <button className='btn btn-success' onClick={() => navigator.clipboard.writeText('0xbF95eeF713b595895922aB4cAb9805E1d58b910d')}>Copy BEP-20 Address</button>
            <img
              alt='Logo'
              className='w-100'
              src={toAbsoluteUrl('/media/logos/usdt.jpg')}
            />
          </div>
        </div>

        <hr/>
        <div>
          <p className='text-center text-danger fs-2'>Contact support 24/7</p>
          <p className='text-center text-primary fs-3'>Facebook: <a href="https://www.facebook.com/QuanDo1994/" target='_blank'>https://www.facebook.com/QuanDo1994/</a></p>
          <p className='text-center text-primary fs-3'>Email: dominhquan12121994@gmail.com</p>
          <p className='text-center text-primary fs-3'>Telegram: <a href="https://t.me/idenperson" target='_blank'>https://t.me/idenperson</a></p>
          <p className='text-center text-primary fs-3'>WhatsApp: <a href="https://wa.me/84967886248" target='_blank'>https://wa.me/84967886248</a></p>
        </div>


        {/*menu*/}
        <MenuTwo active='info'/>
      </div>

    </div>
  )
}

export {CustomerUpgrade}

