import React from 'react'
import {shallowEqual, useSelector, useDispatch} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link} from "react-router-dom";
import {MenuTwo} from "../components/MenuTwo";

const CustomerStudyThink = () => {
  const dispatch = useDispatch()

  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const currentUser = useSelector(({auth}) => auth.user, shallowEqual)
  // báo cáo người lớn - trẻ con
  const isChild = currentUser.user.is_child;

  // đã trả tiền được đại lý duyệt
  const isActive = currentUser.user.is_active;

  const userInfo = useSelector(store => store.userInfo)
  const LANGUAGE = userInfo.language

  return (
    <>
      {isActive
        ? (
          // da kich hoat
          <>
            <div style={{ backgroundColor: '#333', height: '100%'}}>
              <div
                style={{
                  width: WIDTH,
                  height: '100%',
                  margin: 'auto',
                  backgroundColor: '#fff'
                }}
              >

                <div
                  style={{
                    width: '100%',
                    height: '100px',
                    paddingTop: '30px',
                    paddingLeft: '20px',
                    backgroundColor: '#2e84e0',
                    paddingRight: '20px',
                    justifyContent: 'space-between',
                    display: 'flex'
                  }}
                >
                  <h1 style={{
                    color: 'white',
                    fontFamily: 'Linotte-Heavy',
                    fontSize: '24px'
                  }}>
                    {LANGUAGE === 'VI'
                      ? 'Tư duy'
                      : 'Thinking'
                    }

                  </h1>
                  <div>
                    <h1
                      onClick={() => dispatch({
                        type: 'SET_LANGUAGE',
                        payload: {
                          language: 'VI'
                        }
                      })}
                      style={{color: LANGUAGE === 'VI' ? 'white' : '#ccc', display: 'inline-block'}}>
                      VIE
                    </h1>
                    <h1 style={{color: '#ccc', display: 'inline-block', marginLeft: '10px', marginRight: '10px'}}>
                      |
                    </h1>
                    <h1
                      onClick={() => dispatch({
                        type: 'SET_LANGUAGE',
                        payload: {
                          language: 'EN'
                        }
                      })}
                      style={{color: LANGUAGE === 'EN' ? 'white' : '#ccc', display: 'inline-block'}}>
                      ENG
                    </h1>
                  </div>
                </div>

                <div
                  style={{
                    marginTop: '-50px',
                    textAlign: 'center'
                  }}
                >
                  <img style={{ width: '100%', height: 'auto', padding: '15px', borderRadius: '30px' }}
                       src={toAbsoluteUrl('/media/logos/think-back.jpg')}
                       alt=""
                  />
                  {/*<h2 style={{ marginTop: '-40px', color: '#fff' }}>{currentUser.user.name}</h2>*/}
                  <div style={{ padding: '10px 40px', textAlign: 'center' }}>
                    <h4 style={{ color: '#01a1ff', lineHeight: '1.8' }} >"
                      {LANGUAGE === 'VI'
                        ? 'Tri thức dẫn lối thành công'
                        : 'Knowledge leads the way to success'
                      }

                      "</h4>
                  </div>
                </div>

                {/*bài học 1*/}
                <a
                  href="https://youtu.be/HCZV0BdxojU?si=XmLH6C4irZ0Z9opM"
                  target='_blank'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 1: Giới thiệu'
                        : 'Lesson 1: Intro'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 2*/}
                <a
                  href="https://youtu.be/zh3tLd_JTYM?si=0Py4cIus4bpfywAc"
                  target='_blank'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 2: Giới thiệu về phương pháp tư duy "5 câu hỏi tại sao"'
                        : 'Lesson 2: Introduction to the "5 why questions" thinking method'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 3*/}
                <a
                  href="https://youtu.be/yNiyMpF-fgo?si=-sEAwSlf5HDOEmEa"
                  target='_blank'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 3: Thực hành phương pháp tư duy "5 câu hỏi tại sao" - phần 1'
                        : 'Lesson 3: Practice the "5 why questions" thinking method - part 1'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 4*/}
                <a
                  href="https://youtu.be/vQ7eAD0Cv4A?si=g6ImRX3YTfyNciHy"
                  target='_blank'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 4: Thực hành phương pháp tư duy "5 câu hỏi tại sao" - phần 2'
                        : 'Lesson 4: Practice the "5 why questions" thinking method - part 2'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 5*/}
                <a
                  href="https://youtu.be/IY28ahitAeM?si=sE3QN-T84EXiPNVX"
                  target='_blank'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 5: Thực hành phương pháp tư duy "5 câu hỏi tại sao" - phần 3'
                        : 'Lesson 5: Practice the "5 why questions" thinking method - part 3'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 6*/}
                <a
                  href="https://youtu.be/qMpoD8mCwlE?si=UO75aBMsDgnCb62y"
                  target='_blank'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 6: Giới thiệu về phương pháp tư duy "SWOT"'
                        : 'Lesson 6: Introduction to the "SWOT" thinking method'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 7*/}
                <a
                  href="https://youtu.be/HQkfAzAPMr0?si=73Rd8C-r_kzk1j7s"
                  target='_blank'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 7: Thực hành phương pháp tư duy "SWOT" - phần 1'
                        : 'Lesson 7: Practicing the "SWOT" thinking method - part 1'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 8*/}
                <a
                  href="https://youtu.be/jaAahXdV0kY?si=rr4zymxB_GasJbtk"
                  target='_blank'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 8: Thực hành phương pháp tư duy "SWOT" - phần 2'
                        : 'Lesson 8: Practicing the "SWOT" thinking method - part 2'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 9*/}
                <a
                  href="https://youtu.be/xvB3kH_5m9M?si=YOkt6b8-Vqhm5lwV"
                  target='_blank'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 9: Thực hành phương pháp tư duy "SWOT" - phần 3'
                        : 'Lesson 9: Practicing the "SWOT" thinking method - part 3'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 10*/}
                <a
                  href="https://youtu.be/bm7wh4ypWvo?si=Ftku0q9Ft1MT9uoM"
                  target='_blank'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 10: Giới thiệu phương pháp tư duy 5W1H'
                        : 'Lesson 10: Introducing the 5W1H thinking method'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 11*/}
                <a
                  href="https://youtu.be/Uv540ZY_S5U?si=WYlCGb-xAu_i8-eq"
                  target='_blank'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 11: Thực hành phương pháp tư duy 5W1H - phần 1'
                        : 'Lesson 11: Practicing the 5W1H thinking method - part 1'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 12*/}
                <a
                  href="https://youtu.be/-cJDge2-YP0?si=D2LmrOBpLPrTfWgu"
                  target='_blank'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 12: Thực hành phương pháp tư duy 5W1H - phần 2'
                        : 'Lesson 12: Practicing the 5W1H thinking method - part 2'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 13*/}
                <a
                  href="https://youtu.be/nuUpSSOBNjw?si=tvfVA4OUXRrNaF7w"
                  target='_blank'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 13: Thực hành phương pháp tư duy 5W1H - phần 3'
                        : 'Lesson 13: Practicing the 5W1H thinking method - part 3'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 14*/}
                <a
                  href="https://youtu.be/upVzKirGE-g?si=1jUTgcx_a2LXvCqd"
                  target='_blank'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 14: Giới thiệu phương pháp thảo luận đa quan điểm'
                        : 'Lesson 14: Introduce multi-viewpoint discussion method'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 15*/}
                <a
                  href="https://youtu.be/VQsPKWZXywY?si=UIl4j4FZm465uKfF"
                  target='_blank'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 15: Thực hành phương pháp thảo luận đa quan điểm - phần 1'
                        : 'Lesson 15: Practicing the multi-viewpoint discussion method - part 1'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 16*/}
                <a
                  href="https://youtu.be/Y_nGcAqGJwA?si=c7Qg8_hvKGO6sUVQ"
                  target='_blank'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 16: Thực hành phương pháp thảo luận đa quan điểm - phần 2'
                        : 'Lesson 16: Practicing the multi-viewpoint discussion method - part 2'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 17*/}
                <a
                  href="https://youtu.be/jfWyxLEgun4?si=VuAl8jAl2xIG-tLA"
                  target='_blank'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 17: Thực hành phương pháp thảo luận đa quan điểm - phần 3'
                        : 'Lesson 17: Practicing the multi-viewpoint discussion method - part 3'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 18*/}
                <a
                  href="https://youtu.be/Sm9bpgObJBQ?si=pem9EfzexbytF5SF"
                  target='_blank'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 18: Ôn tập - phần 1'
                        : 'Lesson 18: Review - part 1'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 19*/}
                <a
                  href="https://youtu.be/8FIoRsapTG0?si=ZXbg1JIHb0JtE7Sp"
                  target='_blank'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 19: Ôn tập - phần 2'
                        : 'Lesson 19: Review - part 2'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 20*/}
                <a
                  href="https://youtu.be/7NXsaTh059g?si=UmqZyfKdncFbmRql"
                  target='_blank'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 20: Ôn tập - phần 3'
                        : 'Lesson 20: Review - part 3'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 21*/}
                <a
                  href="https://youtu.be/KTtYyD1FjAM?si=TXjvsozVUTbtNIP_"
                  target='_blank'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 21: Ôn tập - phần 4'
                        : 'Lesson 21: Review - part 4'
                      }

                    </h3>
                  </div>
                </a>

                <MenuTwo active='study'/>
              </div>

            </div>
          </>
        )
        : (
          // chua kich hoat
          <>
            <div style={{ backgroundColor: '#333', height: '100%'}}>
              <div
                style={{
                  width: WIDTH,
                  height: '100%',
                  margin: 'auto',
                  backgroundColor: '#fff'
                }}
              >
                <img style={{ width: '100%', height: window.innerHeight - 70}} src={toAbsoluteUrl('/media/logos/inactive-study3.jpg')} alt=""/>

                <MenuTwo active='study'/>
              </div>

            </div>
          </>
        )
      }
    </>
  )

}

export {CustomerStudyThink}
