/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import axios from "axios";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory, useParams, Link } from "react-router-dom";
import {KTSVG} from "../../../_metronic/helpers";
import {BACKEND_URL} from "../../modules/auth/redux/AuthCRUD";

const SaleAutoApprove = () => {
  const {id} = useParams()
  const intl = useIntl()
  const [provinces, setProvinces] = useState([])
  const [newAccountQuantity, setNewAccountQuantity] = useState(0)
  const [shareholderAccountQuantities, setShareholderAccountQuantities] = useState([])
  const [province, setProvince] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [validateErrorMsgArr, setValidateErrorMsgArr] = useState([])
  const [name, setName] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [provinceId, setProvinceId] = useState(1)
  const [auto, setAuto] = useState(0)
  const accessToken = useSelector(({auth}) => auth.accessToken, shallowEqual)
  const history = useHistory()
  const currentUser = useSelector(({auth}) => auth.user, shallowEqual)

  const handleSave = () => {
    try {
      setIsLoading(true)
      axios.post(`${BACKEND_URL}/sale/auto-approve`, {
        access_token: accessToken,
        auto: auto,
      }).then(data => data.data)
        .then(resData => {
          setIsLoading(false)
          if (resData.error_code === 1) {
            toast("Cập nhật duyệt tự động thành công", {
              onClose: () => {
                history.push('/dashboard')
              }
            })
          } else {
            setErrMsg(resData.message ?? 'Đã xảy ra lỗi, vui lòng liên hệ quản trị viên')
          }
        })
        .catch(err => {
          console.log(err)
          setIsLoading(false)
          setErrMsg('Đã xảy ra lỗi, vui lòng liên hệ quản trị viên')
        })
    }
    catch (err) {
      setIsLoading(false)
      toast("Đã xảy ra lỗi, vui lòng liên hệ quản trị viên")
    }
  }

  return (
    <>
      <ToastContainer />
      <div className="card card-custom">
        <div className="card-header">
          <h3 className="card-title">
            Bật/tắt chế độ duyệt tự động
          </h3>
        </div>
        <form>
          <div className="card-body">
            <div className="form-group mt-5">
              <h4>Trạng thái duyệt hiện tại: <span className='text-danger'>{ currentUser.user.is_paid == 1 ? 'Duyệt tự động' : 'Duyệt thủ công' }</span> </h4>
              <label htmlFor="pickProvince">Chọn chế độ duyệt <span className="text-danger">*</span></label>
              <select className="form-control" id="pickProvince" value={auto} onChange={(e) => setAuto(e.target.value)}>
                <option key='0' value='0'>Duyệt thủ công</option>
                <option key='1' value='1'>Duyệt tự động</option>
              </select>
            </div>
            <div className="form-group mt-10">
              <p className="text-danger">{errMsg ?? ''}</p>
            </div>
          </div>
          <div className="card-footer">
            <button type="button" className="btn btn-primary mx-2" disabled={isLoading} onClick={() => {handleSave()}}>Lưu</button>
            <Link to={'/dashboard'}>
              <button type="button" className="btn btn-secondary mx-2">Quay về</button>
            </Link>
          </div>
        </form>
      </div>
    </>
  )
}

export {SaleAutoApprove}
