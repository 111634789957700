/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {TableShareholderApproveQuantity} from "./TableShareholderApproveQuantity";

const ManageShareholderApproveQuantity: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Danh sách tài khoản chờ duyệt</PageTitle>
      <div className='row gy-5 gx-xl-8'>
        <div className='col-xl-12'>
          <TableShareholderApproveQuantity className='card-xxl-stretch mb-5 mb-xl-8' />
        </div>
      </div>
    </>
  )
}

export {ManageShareholderApproveQuantity}
