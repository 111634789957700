/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import * as auth from "../modules/auth/redux/AuthRedux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link} from "react-router-dom";
import {RootState} from "../../setup";
import {MenuTwo} from "../components/MenuTwo";

const CustomerInfo = () => {
  const dispatch = useDispatch()
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const currentUser = useSelector(({auth}) => auth.user, shallowEqual)
  const userInfo = useSelector(store => store.userInfo)
  const LANGUAGE = userInfo.language
  const isActive = currentUser.user.is_active;
  const isScan = currentUser.user.is_scan;
  const isCheck = currentUser.user.is_check;
  const isChild = currentUser.user.is_child;
  const parent = currentUser.user.parent ?? null
  const childs = currentUser.user.childs ?? []
  let totalCommission = 0
  if (childs.length > 0) {
    for (let child of childs) {
      if (child.is_paid) {
        totalCommission += 10

      }
    }
  }

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          height: '100%',
          margin: 'auto',
          backgroundColor: '#fff'
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100px',
            paddingTop: '30px',
            paddingLeft: '20px',
            backgroundColor: '#a14ad9',
            paddingRight: '20px',
            justifyContent: 'space-between',
            display: 'flex'
          }}
        >
          <h1 style={{
            color: 'white',
            fontFamily: 'Linotte-Heavy',
            fontSize: '24px'
          }}>
            {LANGUAGE === 'VI'
              ? 'Người dùng'
              : 'User'
            }
          </h1>
        </div>

        <div
          style={{
            marginTop: '-50px',
            textAlign: 'center'
          }}
        >
          {isActive && isScan && isCheck
            ?
            <img style={{width: '100%', height: 'auto', padding: '15px', borderRadius: '30px'}}
                src={isChild ? currentUser.user.character.main_image_link : currentUser.user.character_adult.main_image_link} alt=""/>
            :
            <img style={{width: '100%', height: 'auto', padding: '15px', borderRadius: '30px'}}
                 src={toAbsoluteUrl('/media/logos/inactive-user.jpg')} alt=""/>
          }
          <h2 style={{ marginTop: '-40px', color: '#fff' }}>{currentUser.user.name}</h2>
          <div style={{ padding: '10px 40px', textAlign: 'center' }}>
            <h4 style={{ color: '#01a1ff', lineHeight: '1.8' }} >
              {LANGUAGE === 'VI'
                ? 'Số điện thoại: '
                : 'Phone number: '
              }
              {currentUser.user.phone}
            </h4>
            {isActive ? <h4 style={{ color: '#01a1ff', lineHeight: '1.8' }} >
              {LANGUAGE === 'VI'
                ? 'Mã quét mặt: '
                : 'Face scanning code: '
              }
              {currentUser.user.scan_code}
            </h4> : <></>}
            <h4 style={{ color: 'red', lineHeight: '1.6', fontFamily: 'Linotte-Regular' }} >
              {LANGUAGE === 'VI'
                ? '*Bạn hãy lưu lại mã quét mặt để thuận tiện trong việc thay đổi hoặc tìm lại mật khẩu'
                : '*Please save the face scan code for convenience in changing or finding your password'
              }
            </h4>

            <hr/>
            <p className='fs-3 fw-bolder text-warning'>Send referral link to friends and get corresponding commission</p>
            <p className='fs-4'><a href={'https://idenperson.com/step-one?refer-phone=' + currentUser.user.phone}>{'https://idenperson.com/step-one?refer-phone=' + currentUser.user.phone}</a></p>
            <p><button
              onClick={() => navigator.clipboard.writeText('https://idenperson.com/step-one?refer-phone=' + currentUser.user.phone)}
              className='btn btn-success'>Copy</button></p>

            <hr/>
            {parent && <>
              <p className='fs-3 fw-bolder text-primary'>The person who referred you</p>
              <p className='fs-4'>{parent.name + ' + ' + parent.phone}</p>
            </>}

            <hr/>
            {childs.length > 0 && <>
              <p className='fs-3 fw-bolder text-primary'>The people you refer and the commissions you receive accordingly</p>
              {childs.map(child => <div key={child.phone}>
                <p className='fs-4'>{child.name + ' _ ' + child.phone} <span className='text-danger'>{child.is_paid ? ' + 10 USD Premium' : ' + 0 USD non Premium'}</span> </p>
              </div>)}
              <p className='fs-3 fw-bolder text-primary'>Total commission: <span className='text-danger'>{totalCommission} USD</span></p>
            </>}
            <hr/>
            <div>
              <p className='text-center text-danger fs-2'>Contact support for immediate withdrawal 24/7</p>
              <p className='text-center text-primary fs-3'>Facebook: <a href="https://www.facebook.com/QuanDo1994/" target='_blank'>https://www.facebook.com/QuanDo1994/</a></p>
              <p className='text-center text-primary fs-3'>Email: dominhquan12121994@gmail.com</p>
              <p className='text-center text-primary fs-3'>Telegram: <a href="https://t.me/idenperson" target='_blank'>https://t.me/idenperson</a></p>
              <p className='text-center text-primary fs-3'>WhatsApp: <a href="https://wa.me/84967886248" target='_blank'>https://wa.me/84967886248</a></p>
            </div>
          </div>
        </div>

        <Link to={{
          pathname: '/customer/info-change-password',
          state: { test: "hello" }
        }}>
          <div style={{ padding: '10px' }}>
            <div style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#ff9000' }}>
              <h4 style={{ color: '#fff' }}>
                {LANGUAGE === 'VI'
                  ? 'Đổi mật khẩu'
                  : 'Change Password'
                }
              </h4>
            </div>
          </div>
        </Link>

        <Link to={{
          pathname: '/customer/info-change-name',
          state: { test: "hello" }
        }}>
          <div style={{ padding: '10px' }}>
            <div style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#ff9000' }}>
              <h4 style={{ color: '#fff' }}>
                {LANGUAGE === 'VI'
                  ? 'Đổi tên'
                  : 'Change Name'
                }
              </h4>
            </div>
          </div>
        </Link>

        <div style={{ padding: '10px' }}>
          <div
            onClick={() => dispatch(auth.actions.logout())}
            style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#01a1ff' }}>
            <h4 style={{ color: '#fff' }}>
              {LANGUAGE === 'VI'
                ? 'Đăng xuất'
                : 'Log out'
              }
            </h4>
          </div>
        </div>


        {/*menu*/}
        <MenuTwo active='info'/>
      </div>

    </div>
  )
}

export {CustomerInfo}

