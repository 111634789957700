/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import * as auth from "../modules/auth/redux/AuthRedux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link} from "react-router-dom";
import {RootState} from "../../setup";

const CustomerIntro = () => {
  const dispatch = useDispatch()
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const currentUser = useSelector(({auth}) => auth.user, shallowEqual)

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          height: '100%',
          margin: 'auto',
          backgroundColor: '#fff'
        }}
      >
        <iframe
          style={{ width: '100%', height: '100%', zIndex: '-1', paddingBottom: '70px' }}
          src="https://www.robotanan.com/"
          frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"
        />





        {/*height menu*/}
        <div style={{height: '70px'}}></div>
        {/*menu*/}
        <div style={{
          position: 'fixed',
          bottom: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          width: WIDTH,
          height: '70px',
          margin: 'auto',
          zIndex: '1'
        }}>
          <div style={{
            width: '25%',
            height: '100%',
            backgroundColor: '#fff',
            display: 'inline-block',
            textAlign: 'center',
            paddingTop: '10px'
          }}>
            <Link to='/customer/report-image'>
              <div style={{padding: '0px 13px'}}>
                <div style={{}}>
                  <img
                    alt='Logo'
                    className='h-50px'
                    src={toAbsoluteUrl('/media/logos/1-bao-cao.png')}
                  />
                </div>
              </div>
            </Link>
          </div>
          <div style={{
            width: '25%',
            height: '100%',
            backgroundColor: '#fff',
            display: 'inline-block',
            textAlign: 'center',
            paddingTop: '10px'
          }}>
            <Link to='/customer/list-study'>
              <div style={{padding: '0px 13px'}}>
                <div style={{}}>
                  <img
                    alt='Logo'
                    className='h-50px'
                    src={toAbsoluteUrl('/media/logos/2-khoa-hoc.png')}
                  />
                </div>
              </div>
            </Link>
          </div>
          <div style={{
            width: '25%',
            height: '100%',
            backgroundColor: '#fff',
            display: 'inline-block',
            textAlign: 'center',
            paddingTop: '10px'
          }}>
            <Link to='/customer/intro'>
              <div style={{padding: '0px 13px'}}>
                <div style={{border: '1px solid #70cfff', borderRadius: '10px', backgroundColor: '#ade0fd'}}>
                  <img
                    alt='Logo'
                    className='h-50px'
                    src={toAbsoluteUrl('/media/logos/3-gioi-thieu.png')}
                  />
                </div>
              </div>
            </Link>
          </div>
          <div style={{
            width: '25%',
            height: '100%',
            backgroundColor: '#fff',
            display: 'inline-block',
            textAlign: 'center',
            paddingTop: '10px'
          }}>
            <Link to='/customer/info'>
              <div style={{padding: '0px 13px'}}>
                <div style={{}}>
                  <img
                    alt='Logo'
                    className='h-50px'
                    src={toAbsoluteUrl('/media/logos/4-tai-khoan.png')}
                  />
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>

    </div>
  )
}

export {CustomerIntro}
