/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useRef, useState} from 'react'
import * as auth from "../modules/auth/redux/AuthRedux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link, useLocation} from "react-router-dom";
import {MenuTwo} from '../components/MenuTwo'
import {FullReport} from "../pages/FullReport";
import {NonReport} from "../pages/NonReport";
import {Lv1Report} from "../pages/Lv1Report";
import axios from "axios";
import {BACKEND_URL} from "../modules/auth/redux/AuthCRUD";

const CustomerReportImage = () => {
  const dispatch = useDispatch()
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const currentUser = useSelector(({auth}) => auth.user, shallowEqual)

  // đã trả tiền được đại lý duyệt
  const isActive = currentUser.user.is_active;

  // đã quét mặt
  const isScan = currentUser.user.is_scan;
  const isCheck = currentUser.user.is_check;
  const isPaid = currentUser.user.is_paid;

  // nếu is_check, is_scan thì đã xong
  // is_paid thì mở báo cáo

  const userInfo = useSelector(store => store.userInfo)
  const LANGUAGE = userInfo.language

  return (
    <>
      {isCheck && isScan
        ?
          <>
            {isPaid ?
              <FullReport />
              :
              <NonReport />
            }
          </>
        :
          <>
            <div style={{ backgroundColor: '#333', height: '100%'}}>
              <div
                style={{
                  width: WIDTH,
                  height: '100%',
                  margin: 'auto',
                  backgroundColor: '#fff',
                  padding: '20px'
                }}
              >
                <h2>AI is performing personality analysis, please wait 3 minutes and reload the page</h2>

                <div style={{ padding: '10px' }}>
                  <div
                    onClick={() => window.location.reload()}
                    style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#32b50a', marginBottom: '20px' }}>
                    <h4 style={{ color: '#fff', cursor: 'pointer' }}>
                      Reload
                    </h4>
                  </div>

                  <div
                    onClick={() => dispatch(auth.actions.logout())}
                    style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#01a1ff' }}>
                    <h4 style={{ color: '#fff', cursor: 'pointer' }}>
                      {LANGUAGE === 'VI'
                        ? 'Đăng xuất'
                        : 'Log out'
                      }
                    </h4>
                  </div>
                </div>
              </div>

            </div>
          </>
      }

    </>
  )
}

export {CustomerReportImage}
