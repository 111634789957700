import React from 'react'
import {shallowEqual, useSelector, useDispatch} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link} from "react-router-dom";
import {MenuTwo} from "../components/MenuTwo";

const CustomerStudy = () => {
  const dispatch = useDispatch()

  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const currentUser = useSelector(({auth}) => auth.user, shallowEqual)
  // báo cáo người lớn - trẻ con
  const isChild = currentUser.user.is_child;

  // đã trả tiền được đại lý duyệt
  const isActive = currentUser.user.is_active;

  const userInfo = useSelector(store => store.userInfo)
  const LANGUAGE = userInfo.language

  return (
    <>
      {isActive
        ? (
          // da kich hoat
          <>
            {isChild
              ? (
                // da kich hoat tre em
                <>
                  <div style={{ backgroundColor: '#333', height: '100%'}}>
                    <div
                      style={{
                        width: WIDTH,
                        height: '100%',
                        margin: 'auto',
                        backgroundColor: '#fff'
                      }}
                    >

                      <div
                        style={{
                          width: '100%',
                          height: '100px',
                          paddingTop: '30px',
                          paddingLeft: '20px',
                          backgroundColor: '#2e84e0',
                          paddingRight: '20px',
                          justifyContent: 'space-between',
                          display: 'flex'
                        }}
                      >
                        <h1 style={{
                          color: 'white',
                          fontFamily: 'Linotte-Heavy',
                          fontSize: '24px'
                        }}>
                          {LANGUAGE === 'VI'
                            ? 'Hướng nghiệp'
                            : 'Vocational guidance'
                          }

                        </h1>
                        <div>
                          <h1
                            onClick={() => dispatch({
                              type: 'SET_LANGUAGE',
                              payload: {
                                language: 'VI'
                              }
                            })}
                            style={{color: LANGUAGE === 'VI' ? 'white' : '#ccc', display: 'inline-block'}}>
                            VIE
                          </h1>
                          <h1 style={{color: '#ccc', display: 'inline-block', marginLeft: '10px', marginRight: '10px'}}>
                            |
                          </h1>
                          <h1
                            onClick={() => dispatch({
                              type: 'SET_LANGUAGE',
                              payload: {
                                language: 'EN'
                              }
                            })}
                            style={{color: LANGUAGE === 'EN' ? 'white' : '#ccc', display: 'inline-block'}}>
                            ENG
                          </h1>
                        </div>
                      </div>

                      <div
                        style={{
                          marginTop: '-50px',
                          textAlign: 'center'
                        }}
                      >
                        <img style={{ width: '100%', height: 'auto', padding: '15px', borderRadius: '30px' }}
                             src={toAbsoluteUrl(LANGUAGE === 'VI' ? '/media/logos/study.jpg' : '/media/logos/study_en.jpg')}
                             alt=""
                        />
                        {/*<h2 style={{ marginTop: '-40px', color: '#fff' }}>{currentUser.user.name}</h2>*/}
                        <div style={{ padding: '10px 40px', textAlign: 'center' }}>
                          <h4 style={{ color: '#01a1ff', lineHeight: '1.8' }} >"
                            {LANGUAGE === 'VI'
                              ? 'Tri thức dẫn lối thành công'
                              : 'Knowledge leads the way to success'
                            }

                            "</h4>
                        </div>
                      </div>

                      {/*<div style={{ textAlign: 'center' }}>*/}
                      {/*  <h3>Tính năng sẽ ra mắt vào ngày 10/03/2024</h3>*/}
                      {/*</div>*/}

                      <Link to={{
                        pathname: '/customer/study-des',
                        state: LANGUAGE === 'VI' ? {
                          videoURL: 'https://player.vimeo.com/video/921490956?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t1.jpg',
                          desImageURL: 'https://backend.robotanan.com/hn1.jpg',
                        } : {
                          videoURL: 'https://player.vimeo.com/video/925858762?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t1.jpg',
                          desImageURL: 'https://backend.robotanan.com/he1.jpg',
                        }
                      }}>
                        <div
                          style={{
                            margin: '20px 15px',
                            padding: '15px',
                            position: 'relative',
                            border: '2px solid #dddddd',
                            borderRadius: '10px',
                            boxShadow: '0px 4px #dddddd',
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: 'Linotte-Heavy'
                            }}
                          >
                            {LANGUAGE === 'VI'
                              ? 'Bài học 1: Hiểu đúng về hướng nghiệp'
                              : 'Lesson 1: Correct understanding of career guidance'
                            }

                          </h3>
                          {/*<img style={{ position: 'absolute', right: '10px', bottom: '0px', width: '50px' }} src={toAbsoluteUrl('/media/logos/1-tong-quan.png')} alt=""/>*/}
                        </div>
                      </Link>

                      <Link to={{
                        pathname: '/customer/study-des',
                        state: LANGUAGE === 'VI' ? {
                          videoURL: 'https://player.vimeo.com/video/921490976?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t2.jpg',
                          desImageURL: 'https://backend.robotanan.com/hn2.jpg',
                        } : {
                          videoURL: 'https://player.vimeo.com/video/925858826?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t2.jpg',
                          desImageURL: 'https://backend.robotanan.com/he2.jpg',
                        }
                      }}>
                        <div
                          style={{
                            margin: '20px 15px',
                            padding: '15px',
                            position: 'relative',
                            border: '2px solid #dddddd',
                            borderRadius: '10px',
                            boxShadow: '0px 4px #dddddd',
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: 'Linotte-Heavy'
                            }}
                          >
                            {LANGUAGE === 'VI'
                              ? 'Bài học 2: Những yếu tố tác động đến việc chọn nghề'
                              : 'Lesson 2: Factors affecting career choice'
                            }

                          </h3>
                          {/*<img style={{ position: 'absolute', right: '10px', bottom: '0px', width: '50px' }} src={toAbsoluteUrl('/media/logos/1-tong-quan.png')} alt=""/>*/}
                        </div>
                      </Link>

                      <Link to={{
                        pathname: '/customer/study-des',
                        state: LANGUAGE === 'VI' ? {
                          videoURL: 'https://player.vimeo.com/video/921490993?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t3.jpg',
                          desImageURL: 'https://backend.robotanan.com/hn3.jpg',
                        } : {
                          videoURL: 'https://player.vimeo.com/video/925858878?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t3.jpg',
                          desImageURL: 'https://backend.robotanan.com/he3.jpg',
                        }
                      }}>
                        <div
                          style={{
                            margin: '20px 15px',
                            padding: '15px',
                            position: 'relative',
                            border: '2px solid #dddddd',
                            borderRadius: '10px',
                            boxShadow: '0px 4px #dddddd',
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: 'Linotte-Heavy'
                            }}
                          >
                            {LANGUAGE === 'VI'
                              ? 'Bài học 3: Sở thích'
                              : 'Lesson 3: Hobbies'
                            }

                          </h3>
                          {/*<img style={{ position: 'absolute', right: '10px', bottom: '0px', width: '50px' }} src={toAbsoluteUrl('/media/logos/1-tong-quan.png')} alt=""/>*/}
                        </div>
                      </Link>


                      <Link to={{
                        pathname: '/customer/study-des',
                        state: LANGUAGE === 'VI' ? {
                          videoURL: 'https://player.vimeo.com/video/921491005?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t4.jpg',
                          desImageURL: 'https://backend.robotanan.com/hn4.jpg',
                        } : {
                          videoURL: 'https://player.vimeo.com/video/925858926?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t4.jpg',
                          desImageURL: 'https://backend.robotanan.com/he4.jpg',
                        }
                      }}>
                        <div
                          style={{
                            margin: '20px 15px',
                            padding: '15px',
                            position: 'relative',
                            border: '2px solid #dddddd',
                            borderRadius: '10px',
                            boxShadow: '0px 4px #dddddd',
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: 'Linotte-Heavy'
                            }}
                          >
                            {LANGUAGE === 'VI'
                              ? 'Bài học 4: Tố chất cá nhân'
                              : 'Lesson 4: Personal qualities'
                            }

                          </h3>
                          {/*<img style={{ position: 'absolute', right: '10px', bottom: '0px', width: '50px' }} src={toAbsoluteUrl('/media/logos/1-tong-quan.png')} alt=""/>*/}
                        </div>
                      </Link>


                      <Link to={{
                        pathname: '/customer/study-des',
                        state: LANGUAGE === 'VI' ? {
                          videoURL: 'https://player.vimeo.com/video/921491018?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t5.jpg',
                          desImageURL: 'https://backend.robotanan.com/hn5.jpg',
                        } : {
                          videoURL: 'https://player.vimeo.com/video/925858968?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t5.jpg',
                          desImageURL: 'https://backend.robotanan.com/he5.jpg',
                        }
                      }}>
                        <div
                          style={{
                            margin: '20px 15px',
                            padding: '15px',
                            position: 'relative',
                            border: '2px solid #dddddd',
                            borderRadius: '10px',
                            boxShadow: '0px 4px #dddddd',
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: 'Linotte-Heavy'
                            }}
                          >
                            {LANGUAGE === 'VI'
                              ? 'Bài học 5: Nhu cầu và khả năng của gia đình'
                              : 'Lesson 5: Family needs and capabilities'
                            }

                          </h3>
                          {/*<img style={{ position: 'absolute', right: '10px', bottom: '0px', width: '50px' }} src={toAbsoluteUrl('/media/logos/1-tong-quan.png')} alt=""/>*/}
                        </div>
                      </Link>


                      <Link to={{
                        pathname: '/customer/study-des',
                        state: LANGUAGE === 'VI' ? {
                          videoURL: 'https://player.vimeo.com/video/921491030?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t6.jpg',
                          desImageURL: 'https://backend.robotanan.com/hn6.jpg',
                        } : {
                          videoURL: 'https://player.vimeo.com/video/925859014?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t6.jpg',
                          desImageURL: 'https://backend.robotanan.com/he6.jpg',
                        }
                      }}>
                        <div
                          style={{
                            margin: '20px 15px',
                            padding: '15px',
                            position: 'relative',
                            border: '2px solid #dddddd',
                            borderRadius: '10px',
                            boxShadow: '0px 4px #dddddd',
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: 'Linotte-Heavy'
                            }}
                          >
                            {LANGUAGE === 'VI'
                              ? 'Bài học 6: Nhu cầu và xu hướng của xã hội'
                              : 'Lesson 6: Society\'s needs and trends'
                            }

                          </h3>
                          {/*<img style={{ position: 'absolute', right: '10px', bottom: '0px', width: '50px' }} src={toAbsoluteUrl('/media/logos/1-tong-quan.png')} alt=""/>*/}
                        </div>
                      </Link>


                      <Link to={{
                        pathname: '/customer/study-des',
                        state: LANGUAGE === 'VI' ? {
                          videoURL: 'https://player.vimeo.com/video/921491057?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t7.jpg',
                          desImageURL: 'https://backend.robotanan.com/hn7.jpg',
                        } : {
                          videoURL: 'https://player.vimeo.com/video/925859077?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t7.jpg',
                          desImageURL: 'https://backend.robotanan.com/he7.jpg',
                        }
                      }}>
                        <div
                          style={{
                            margin: '20px 15px',
                            padding: '15px',
                            position: 'relative',
                            border: '2px solid #dddddd',
                            borderRadius: '10px',
                            boxShadow: '0px 4px #dddddd',
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: 'Linotte-Heavy'
                            }}
                          >
                            {LANGUAGE === 'VI'
                              ? 'Bài học 7: Phân tích nghề nghiệp bằng phương pháp tư duy'
                              : 'Lesson 7: Analyze careers using thinking methods'
                            }

                          </h3>
                          {/*<img style={{ position: 'absolute', right: '10px', bottom: '0px', width: '50px' }} src={toAbsoluteUrl('/media/logos/1-tong-quan.png')} alt=""/>*/}
                        </div>
                      </Link>


                      <Link to={{
                        pathname: '/customer/study-des',
                        state: LANGUAGE === 'VI' ? {
                          videoURL: 'https://player.vimeo.com/video/921491080?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t8.jpg',
                          desImageURL: 'https://backend.robotanan.com/hn8.jpg',
                        } : {
                          videoURL: 'https://player.vimeo.com/video/925859152?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t8.jpg',
                          desImageURL: 'https://backend.robotanan.com/he8.jpg',
                        }
                      }}>
                        <div
                          style={{
                            margin: '20px 15px',
                            padding: '15px',
                            position: 'relative',
                            border: '2px solid #dddddd',
                            borderRadius: '10px',
                            boxShadow: '0px 4px #dddddd',
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: 'Linotte-Heavy'
                            }}
                          >
                            {LANGUAGE === 'VI'
                              ? 'Bài học 8: Tìm hiểu, tham vấn chuyên gia'
                              : 'Lesson 8: Learn and consult experts'
                            }

                          </h3>
                          {/*<img style={{ position: 'absolute', right: '10px', bottom: '0px', width: '50px' }} src={toAbsoluteUrl('/media/logos/1-tong-quan.png')} alt=""/>*/}
                        </div>
                      </Link>


                      <Link to={{
                        pathname: '/customer/study-des',
                        state: LANGUAGE === 'VI' ? {
                          videoURL: 'https://player.vimeo.com/video/921491092?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t9.jpg',
                          desImageURL: 'https://backend.robotanan.com/hn9.jpg',
                        } : {
                          videoURL: 'https://player.vimeo.com/video/925859196?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t9.jpg',
                          desImageURL: 'https://backend.robotanan.com/he9.jpg',
                        }
                      }}>
                        <div
                          style={{
                            margin: '20px 15px',
                            padding: '15px',
                            position: 'relative',
                            border: '2px solid #dddddd',
                            borderRadius: '10px',
                            boxShadow: '0px 4px #dddddd',
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: 'Linotte-Heavy'
                            }}
                          >
                            {LANGUAGE === 'VI'
                              ? 'Bài học 9: Thử nghề'
                              : 'Lesson 9: Try your career'
                            }

                          </h3>
                          {/*<img style={{ position: 'absolute', right: '10px', bottom: '0px', width: '50px' }} src={toAbsoluteUrl('/media/logos/1-tong-quan.png')} alt=""/>*/}
                        </div>
                      </Link>


                      <Link to={{
                        pathname: '/customer/study-des',
                        state: LANGUAGE === 'VI' ? {
                          videoURL: 'https://player.vimeo.com/video/921491112?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t10.jpg',
                          desImageURL: 'https://backend.robotanan.com/hn10.jpg',
                        } : {
                          videoURL: 'https://player.vimeo.com/video/925859239?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t10.jpg',
                          desImageURL: 'https://backend.robotanan.com/he10.jpg',
                        }
                      }}>
                        <div
                          style={{
                            margin: '20px 15px',
                            padding: '15px',
                            position: 'relative',
                            border: '2px solid #dddddd',
                            borderRadius: '10px',
                            boxShadow: '0px 4px #dddddd',
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: 'Linotte-Heavy'
                            }}
                          >
                            {LANGUAGE === 'VI'
                              ? 'Bài học 10: Hòa hợp các quan điểm và nhu cầu'
                              : 'Lesson 10: Reconcile perspectives and needs'
                            }

                          </h3>
                          {/*<img style={{ position: 'absolute', right: '10px', bottom: '0px', width: '50px' }} src={toAbsoluteUrl('/media/logos/1-tong-quan.png')} alt=""/>*/}
                        </div>
                      </Link>





                      {/*height menu*/}
                      <div style={{height: '70px'}}></div>
                      {/*menu*/}
                      <div style={{
                        position: 'fixed',
                        bottom: 0,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: WIDTH,
                        height: '70px',
                        margin: 'auto'
                      }}>
                        <div style={{
                          width: '25%',
                          height: '100%',
                          backgroundColor: '#fff',
                          display: 'inline-block',
                          textAlign: 'center',
                          paddingTop: '10px'
                        }}>
                          <Link to='/customer/report-image'>
                            <div style={{padding: '0px 13px'}}>
                              <div style={{}}>
                                <img
                                  alt='Logo'
                                  className='h-50px'
                                  src={toAbsoluteUrl('/media/logos/1-bao-cao.png')}
                                />
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div style={{
                          width: '25%',
                          height: '100%',
                          backgroundColor: '#fff',
                          display: 'inline-block',
                          textAlign: 'center',
                          paddingTop: '10px'
                        }}>
                          <Link to='/customer/list-study'>
                            <div style={{padding: '0px 13px'}}>
                              <div style={{border: '1px solid #70cfff', borderRadius: '10px', backgroundColor: '#ade0fd'}}>
                                <img
                                  alt='Logo'
                                  className='h-50px'
                                  src={toAbsoluteUrl('/media/logos/2-khoa-hoc.png')}
                                />
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div style={{
                          width: '25%',
                          height: '100%',
                          backgroundColor: '#fff',
                          display: 'inline-block',
                          textAlign: 'center',
                          paddingTop: '10px'
                        }}>
                          <Link to='/customer/intro'>
                            <div style={{padding: '0px 13px'}}>
                              <div style={{}}>
                                <img
                                  alt='Logo'
                                  className='h-50px'
                                  src={toAbsoluteUrl('/media/logos/3-gioi-thieu.png')}
                                />
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div style={{
                          width: '25%',
                          height: '100%',
                          backgroundColor: '#fff',
                          display: 'inline-block',
                          textAlign: 'center',
                          paddingTop: '10px'
                        }}>
                          <Link to='/customer/info'>
                            <div style={{padding: '0px 13px'}}>
                              <div style={{}}>
                                <img
                                  alt='Logo'
                                  className='h-50px'
                                  src={toAbsoluteUrl('/media/logos/4-tai-khoan.png')}
                                />
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>

                  </div>
                </>
              )
              : (
                // da kich hoat nguoi lon
                <>
                  <div style={{ backgroundColor: '#333', height: '100%'}}>
                    <div
                      style={{
                        width: WIDTH,
                        height: '100%',
                        margin: 'auto',
                        backgroundColor: '#fff'
                      }}
                    >

                      <div
                        style={{
                          width: '100%',
                          height: '100px',
                          paddingTop: '30px',
                          paddingLeft: '20px',
                          backgroundColor: '#2e84e0',
                          paddingRight: '20px',
                          justifyContent: 'space-between',
                          display: 'flex'
                        }}
                      >
                        <h1 style={{
                          color: 'white',
                          fontFamily: 'Linotte-Heavy',
                          fontSize: '24px'
                        }}>
                          {LANGUAGE === 'VI'
                            ? 'Khóa học'
                            : 'Course'
                          }

                        </h1>
                        <div>
                          <h1
                            onClick={() => dispatch({
                              type: 'SET_LANGUAGE',
                              payload: {
                                language: 'VI'
                              }
                            })}
                            style={{color: LANGUAGE === 'VI' ? 'white' : '#ccc', display: 'inline-block'}}>
                            VIE
                          </h1>
                          <h1 style={{color: '#ccc', display: 'inline-block', marginLeft: '10px', marginRight: '10px'}}>
                            |
                          </h1>
                          <h1
                            onClick={() => dispatch({
                              type: 'SET_LANGUAGE',
                              payload: {
                                language: 'EN'
                              }
                            })}
                            style={{color: LANGUAGE === 'EN' ? 'white' : '#ccc', display: 'inline-block'}}>
                            ENG
                          </h1>
                        </div>
                      </div>

                      <div
                        style={{
                          marginTop: '-50px',
                          textAlign: 'center'
                        }}
                      >
                        <img style={{ width: '100%', height: 'auto', padding: '15px', borderRadius: '30px' }}
                             src={toAbsoluteUrl(LANGUAGE === 'VI' ? '/media/logos/study.jpg' : '/media/logos/study_en.jpg')}
                             alt=""
                        />
                        {/*<h2 style={{ marginTop: '-40px', color: '#fff' }}>{currentUser.user.name}</h2>*/}
                        <div style={{ padding: '10px 40px', textAlign: 'center' }}>
                          <h4 style={{ color: '#01a1ff', lineHeight: '1.8' }} >"
                            {LANGUAGE === 'VI'
                              ? 'Tri thức dẫn lối thành công'
                              : 'Knowledge leads the way to success'
                            }

                            "</h4>
                        </div>
                      </div>

                      {/*<div style={{ textAlign: 'center' }}>*/}
                      {/*  <h3>Tính năng sẽ ra mắt vào ngày 10/03/2024</h3>*/}
                      {/*</div>*/}

                      <Link to={{
                        pathname: '/customer/study-des',
                        state: LANGUAGE === 'VI' ? {
                          videoURL: 'https://player.vimeo.com/video/921490956?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t1.jpg',
                          desImageURL: 'https://backend.robotanan.com/hn1.jpg',
                        } : {
                          videoURL: 'https://player.vimeo.com/video/925858762?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t1.jpg',
                          desImageURL: 'https://backend.robotanan.com/he1.jpg',
                        }
                      }}>
                        <div
                          style={{
                            margin: '20px 15px',
                            padding: '15px',
                            position: 'relative',
                            border: '2px solid #dddddd',
                            borderRadius: '10px',
                            boxShadow: '0px 4px #dddddd',
                          }}
                        >
                          <h3>
                            {LANGUAGE === 'VI'
                              ? 'Bài học 1: Hiểu đúng về hướng nghiệp'
                              : 'Lesson 1: Correct understanding of career guidance'
                            }

                          </h3>
                          {/*<img style={{ position: 'absolute', right: '10px', bottom: '0px', width: '50px' }} src={toAbsoluteUrl('/media/logos/1-tong-quan.png')} alt=""/>*/}
                        </div>
                      </Link>

                      <Link to={{
                        pathname: '/customer/study-des',
                        state: LANGUAGE === 'VI' ? {
                          videoURL: 'https://player.vimeo.com/video/921490976?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t2.jpg',
                          desImageURL: 'https://backend.robotanan.com/hn2.jpg',
                        } : {
                          videoURL: 'https://player.vimeo.com/video/925858826?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t2.jpg',
                          desImageURL: 'https://backend.robotanan.com/he2.jpg',
                        }
                      }}>
                        <div
                          style={{
                            margin: '20px 15px',
                            padding: '15px',
                            position: 'relative',
                            border: '2px solid #dddddd',
                            borderRadius: '10px',
                            boxShadow: '0px 4px #dddddd',
                          }}
                        >
                          <h3>
                            {LANGUAGE === 'VI'
                              ? 'Bài học 2: Những yếu tố tác động đến việc chọn nghề'
                              : 'Lesson 2: Factors affecting career choice'
                            }

                          </h3>
                          {/*<img style={{ position: 'absolute', right: '10px', bottom: '0px', width: '50px' }} src={toAbsoluteUrl('/media/logos/1-tong-quan.png')} alt=""/>*/}
                        </div>
                      </Link>

                      <Link to={{
                        pathname: '/customer/study-des',
                        state: LANGUAGE === 'VI' ? {
                          videoURL: 'https://player.vimeo.com/video/921490993?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t3.jpg',
                          desImageURL: 'https://backend.robotanan.com/hn3.jpg',
                        } : {
                          videoURL: 'https://player.vimeo.com/video/925858878?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t3.jpg',
                          desImageURL: 'https://backend.robotanan.com/he3.jpg',
                        }
                      }}>
                        <div
                          style={{
                            margin: '20px 15px',
                            padding: '15px',
                            position: 'relative',
                            border: '2px solid #dddddd',
                            borderRadius: '10px',
                            boxShadow: '0px 4px #dddddd',
                          }}
                        >
                          <h3>
                            {LANGUAGE === 'VI'
                              ? 'Bài học 3: Sở thích'
                              : 'Lesson 3: Hobbies'
                            }

                          </h3>
                          {/*<img style={{ position: 'absolute', right: '10px', bottom: '0px', width: '50px' }} src={toAbsoluteUrl('/media/logos/1-tong-quan.png')} alt=""/>*/}
                        </div>
                      </Link>


                      <Link to={{
                        pathname: '/customer/study-des',
                        state: LANGUAGE === 'VI' ? {
                          videoURL: 'https://player.vimeo.com/video/921491005?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t4.jpg',
                          desImageURL: 'https://backend.robotanan.com/hn4.jpg',
                        } : {
                          videoURL: 'https://player.vimeo.com/video/925858926?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t4.jpg',
                          desImageURL: 'https://backend.robotanan.com/he4.jpg',
                        }
                      }}>
                        <div
                          style={{
                            margin: '20px 15px',
                            padding: '15px',
                            position: 'relative',
                            border: '2px solid #dddddd',
                            borderRadius: '10px',
                            boxShadow: '0px 4px #dddddd',
                          }}
                        >
                          <h3>
                            {LANGUAGE === 'VI'
                              ? 'Bài học 4: Tố chất cá nhân'
                              : 'Lesson 4: Personal qualities'
                            }

                          </h3>
                          {/*<img style={{ position: 'absolute', right: '10px', bottom: '0px', width: '50px' }} src={toAbsoluteUrl('/media/logos/1-tong-quan.png')} alt=""/>*/}
                        </div>
                      </Link>


                      <Link to={{
                        pathname: '/customer/study-des',
                        state: LANGUAGE === 'VI' ? {
                          videoURL: 'https://player.vimeo.com/video/921491018?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t5.jpg',
                          desImageURL: 'https://backend.robotanan.com/hn5.jpg',
                        } : {
                          videoURL: 'https://player.vimeo.com/video/925858968?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t5.jpg',
                          desImageURL: 'https://backend.robotanan.com/he5.jpg',
                        }
                      }}>
                        <div
                          style={{
                            margin: '20px 15px',
                            padding: '15px',
                            position: 'relative',
                            border: '2px solid #dddddd',
                            borderRadius: '10px',
                            boxShadow: '0px 4px #dddddd',
                          }}
                        >
                          <h3>
                            {LANGUAGE === 'VI'
                              ? 'Bài học 5: Nhu cầu và khả năng của gia đình'
                              : 'Lesson 5: Family needs and capabilities'
                            }

                          </h3>
                          {/*<img style={{ position: 'absolute', right: '10px', bottom: '0px', width: '50px' }} src={toAbsoluteUrl('/media/logos/1-tong-quan.png')} alt=""/>*/}
                        </div>
                      </Link>


                      <Link to={{
                        pathname: '/customer/study-des',
                        state: LANGUAGE === 'VI' ? {
                          videoURL: 'https://player.vimeo.com/video/921491030?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t6.jpg',
                          desImageURL: 'https://backend.robotanan.com/hn6.jpg',
                        } : {
                          videoURL: 'https://player.vimeo.com/video/925859014?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t6.jpg',
                          desImageURL: 'https://backend.robotanan.com/he6.jpg',
                        }
                      }}>
                        <div
                          style={{
                            margin: '20px 15px',
                            padding: '15px',
                            position: 'relative',
                            border: '2px solid #dddddd',
                            borderRadius: '10px',
                            boxShadow: '0px 4px #dddddd',
                          }}
                        >
                          <h3>
                            {LANGUAGE === 'VI'
                              ? 'Bài học 6: Nhu cầu và xu hướng của xã hội'
                              : 'Lesson 6: Society\'s needs and trends'
                            }

                          </h3>
                          {/*<img style={{ position: 'absolute', right: '10px', bottom: '0px', width: '50px' }} src={toAbsoluteUrl('/media/logos/1-tong-quan.png')} alt=""/>*/}
                        </div>
                      </Link>


                      <Link to={{
                        pathname: '/customer/study-des',
                        state: LANGUAGE === 'VI' ? {
                          videoURL: 'https://player.vimeo.com/video/921491057?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t7.jpg',
                          desImageURL: 'https://backend.robotanan.com/hn7.jpg',
                        } : {
                          videoURL: 'https://player.vimeo.com/video/925859077?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t7.jpg',
                          desImageURL: 'https://backend.robotanan.com/he7.jpg',
                        }
                      }}>
                        <div
                          style={{
                            margin: '20px 15px',
                            padding: '15px',
                            position: 'relative',
                            border: '2px solid #dddddd',
                            borderRadius: '10px',
                            boxShadow: '0px 4px #dddddd',
                          }}
                        >
                          <h3>
                            {LANGUAGE === 'VI'
                              ? 'Bài học 7: Phân tích nghề nghiệp bằng phương pháp tư duy'
                              : 'Lesson 7: Analyze careers using thinking methods'
                            }

                          </h3>
                          {/*<img style={{ position: 'absolute', right: '10px', bottom: '0px', width: '50px' }} src={toAbsoluteUrl('/media/logos/1-tong-quan.png')} alt=""/>*/}
                        </div>
                      </Link>


                      <Link to={{
                        pathname: '/customer/study-des',
                        state: LANGUAGE === 'VI' ? {
                          videoURL: 'https://player.vimeo.com/video/921491080?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t8.jpg',
                          desImageURL: 'https://backend.robotanan.com/hn8.jpg',
                        } : {
                          videoURL: 'https://player.vimeo.com/video/925859152?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t8.jpg',
                          desImageURL: 'https://backend.robotanan.com/he8.jpg',
                        }
                      }}>
                        <div
                          style={{
                            margin: '20px 15px',
                            padding: '15px',
                            position: 'relative',
                            border: '2px solid #dddddd',
                            borderRadius: '10px',
                            boxShadow: '0px 4px #dddddd',
                          }}
                        >
                          <h3>
                            {LANGUAGE === 'VI'
                              ? 'Bài học 8: Tìm hiểu, tham vấn chuyên gia'
                              : 'Lesson 8: Learn and consult experts'
                            }

                          </h3>
                          {/*<img style={{ position: 'absolute', right: '10px', bottom: '0px', width: '50px' }} src={toAbsoluteUrl('/media/logos/1-tong-quan.png')} alt=""/>*/}
                        </div>
                      </Link>


                      <Link to={{
                        pathname: '/customer/study-des',
                        state: LANGUAGE === 'VI' ? {
                          videoURL: 'https://player.vimeo.com/video/921491092?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t9.jpg',
                          desImageURL: 'https://backend.robotanan.com/hn9.jpg',
                        } : {
                          videoURL: 'https://player.vimeo.com/video/925859196?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t9.jpg',
                          desImageURL: 'https://backend.robotanan.com/he9.jpg',
                        }
                      }}>
                        <div
                          style={{
                            margin: '20px 15px',
                            padding: '15px',
                            position: 'relative',
                            border: '2px solid #dddddd',
                            borderRadius: '10px',
                            boxShadow: '0px 4px #dddddd',
                          }}
                        >
                          <h3>
                            {LANGUAGE === 'VI'
                              ? 'Bài học 9: Thử nghề'
                              : 'Lesson 9: Try your career'
                            }

                          </h3>
                          {/*<img style={{ position: 'absolute', right: '10px', bottom: '0px', width: '50px' }} src={toAbsoluteUrl('/media/logos/1-tong-quan.png')} alt=""/>*/}
                        </div>
                      </Link>


                      <Link to={{
                        pathname: '/customer/study-des',
                        state: LANGUAGE === 'VI' ? {
                          videoURL: 'https://player.vimeo.com/video/921491112?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t10.jpg',
                          desImageURL: 'https://backend.robotanan.com/hn10.jpg',
                        } : {
                          videoURL: 'https://player.vimeo.com/video/925859239?autoplay=1',
                          mainImageURL: 'https://backend.robotanan.com/t10.jpg',
                          desImageURL: 'https://backend.robotanan.com/he10.jpg',
                        }
                      }}>
                        <div
                          style={{
                            margin: '20px 15px',
                            padding: '15px',
                            position: 'relative',
                            border: '2px solid #dddddd',
                            borderRadius: '10px',
                            boxShadow: '0px 4px #dddddd',
                          }}
                        >
                          <h3>
                            {LANGUAGE === 'VI'
                              ? 'Bài học 10: Hòa hợp các quan điểm và nhu cầu'
                              : 'Lesson 10: Reconcile perspectives and needs'
                            }

                          </h3>
                          {/*<img style={{ position: 'absolute', right: '10px', bottom: '0px', width: '50px' }} src={toAbsoluteUrl('/media/logos/1-tong-quan.png')} alt=""/>*/}
                        </div>
                      </Link>

                      <MenuTwo active='study'/>
                    </div>

                  </div>
                </>
              )
            }

          </>
        )
        : (
          // chua kich hoat
          <>
            <div style={{ backgroundColor: '#333', height: '100%'}}>
              <div
                style={{
                  width: WIDTH,
                  height: '100%',
                  margin: 'auto',
                  backgroundColor: '#fff'
                }}
              >
                <img style={{ width: '100%', height: window.innerHeight - 70}} src={toAbsoluteUrl('/media/logos/inactive-study3.jpg')} alt=""/>

                <MenuTwo active='study'/>
              </div>

            </div>
          </>
        )
      }
    </>
  )

}

export {CustomerStudy}
