/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link} from "react-router-dom";

const MenuTwo = ({active}) => {
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const style = {
    menuContainer: {
      position: 'fixed',
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      width: WIDTH,
      height: '70px',
      margin: 'auto'
    },
    menuElement: {
      width: '50%',
      height: '100%',
      backgroundColor: '#fff',
      display: 'inline-block',
      textAlign: 'center',
      paddingTop: '10px',
      paddingBottom: '10px',
    }
  }

  return (
    <>
      <div style={{height: '70px'}}>&nbsp;</div>
      <div style={style.menuContainer}>

        <div style={style.menuElement}>
          <Link to='/customer/report-image'>
            <div style={{padding: '0px 13px'}}>
              <div style={active == 'report' ? {border: '1px solid #70cfff', borderRadius: '10px', backgroundColor: '#ade0fd'} : {}}>
                <img
                  alt='Logo'
                  className='h-50px'
                  src={toAbsoluteUrl('/media/logos/1-bao-cao.png')}
                />
              </div>
            </div>
          </Link>
        </div>

        {/*<div style={style.menuElement}>*/}
        {/*  <Link to='/customer/map'>*/}
        {/*    <div style={{padding: '0px 13px'}}>*/}
        {/*      <div style={active == 'map' ? {border: '1px solid #70cfff', borderRadius: '10px', backgroundColor: '#ade0fd'} : {}}>*/}
        {/*      <img*/}
        {/*          alt='Logo'*/}
        {/*          className='h-50px'*/}
        {/*          src={toAbsoluteUrl('/media/logos/2-khoa-hoc.png')}*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </Link>*/}
        {/*</div>*/}

        <div style={style.menuElement}>
          <Link to='/customer/info'>
            <div style={{padding: '0px 13px'}}>
              <div style={active == 'info' ? {border: '1px solid #70cfff', borderRadius: '10px', backgroundColor: '#ade0fd'} : {}}>
                <img
                  alt='Logo'
                  className='h-50px'
                  src={toAbsoluteUrl('/media/logos/4-tai-khoan.png')}
                />
              </div>
            </div>
          </Link>
        </div>

      </div>
    </>
  )
}

export {MenuTwo}
