/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  MixedWidget2,
  MixedWidget10,
  MixedWidget11,
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
} from '../../../_metronic/partials/widgets'
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import * as auth from "../../modules/auth/redux/AuthRedux";
import {RootState} from "../../../setup";
import {KTSVG, toAbsoluteUrl} from "../../../_metronic/helpers";
import {Link} from "react-router-dom";

const DashboardPage: FC = () => {
  const dispatch = useDispatch()
  const currentUser: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  console.log(currentUser)
  const commissionArr = currentUser.user.commission_transfer ?? []
  let commissionSum = 0;
  if (commissionArr.length >= 1) {
    commissionArr.forEach((commission: any) => commissionSum += commission.value)
  }

  const [quantityDateArr, setQuantityDateArr] = useState([])
  const parent = currentUser.user.parent ?? null
  const roleId = currentUser.user.role_id ?? null

  useEffect(() => {
    // get current agency
    if (currentUser.user.role_id === 5) {
      let agencyAccQuantityArr = currentUser.user.account_quantity_arr
      let quantityDateArr: any = []
      if (agencyAccQuantityArr.length > 0) {
        agencyAccQuantityArr.forEach((accQuantity: any) => {
          if (accQuantity.account_quantity < 0) {
            quantityDateArr.push(accQuantity.change_date)
          }
        })
      }
      let counter: any = {}
      quantityDateArr.forEach((quantityDate: any) => {
        if (counter[quantityDate]) {
          counter[quantityDate] += 1
        } else {
          counter[quantityDate] = 1
        }
      })
      let quantityDateArrValue: any = []
      for (const [key, value] of Object.entries(counter)) {
        quantityDateArrValue.push({
          date: key,
          quantity: value,
        })
      }
      setQuantityDateArr(quantityDateArrValue)
    }
  }, [])

  return (
    <>
      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        <div className='col-xxl-12 text-center'>
          <h3 className='card-title fw-bolder'>Họ và tên: {currentUser.user.name}</h3>
          <h3 className='card-title fw-bolder'>Mã số bí mật: {currentUser.user.scan_code}</h3>
          <h3 className='card-title fw-bolder'>Mã định danh tài khoản: {currentUser.user.alias ?? ''}</h3>
          <h3 className='card-title fw-bolder'>Tên đăng nhập: {currentUser.user.phone}</h3>
          <h3 className='card-title fw-bolder'>Số điện thoại liên hệ: {currentUser.user.note}</h3>

          {(roleId > 2) &&
          <>
            <h3 className='card-title fw-bolder'>Số tài khoản còn lại: {currentUser.user.current_account_quantity ?? 0}</h3>
          </>
          }

          {(roleId === 5 && parent) &&
            <>
              <h3 className='card-title fw-bolder'>CTV giới thiệu: {parent.phone ?? ''} + {parent.name ?? ''} + {parent.alias ?? ''}</h3>
            </>
          }

          <div className='col-xxl-4 d-none'>
            <div className={`card card-xxl-stretch`}>
              {/* begin::Header */}
              <div className='card-header align-items-center border-0 mt-4'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='fw-bolder mb-2 text-dark'>Lịch sử hoa hồng Anan</span>
                  <span className='text-danger fw-bold fs-3'>Tổng cộng {commissionSum} VNĐ</span>
                </h3>
                <div className='card-toolbar'>
                  {/* begin::Menu */}
                  <button
                    type='button'
                    className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                  >
                    <KTSVG
                      path='/media/icons/duotone/Layout/Layout-4-blocks-2.svg'
                      className='svg-icon-2'
                    />
                  </button>
                  {/* end::Menu */}
                </div>
              </div>
              {/* end::Header */}
              {/* begin::Body */}
              <div className='card-body pt-5'>
                {commissionArr.length < 1 && <>
                  <div className='fw-mormal timeline-content text-muted ps-3'>
                    Chưa có hoa hồng
                  </div>
                </>}

                {commissionArr.length >= 1 && <>
                  <div className='timeline-label'>

                    {commissionArr.map((commission: any) =>
                      <div className='timeline-item' key={commission.id}>
                        <div className='timeline-label fw-bolder text-gray-800 fs-6 w-60px'>
                          {commission.value > 0 ? '+' : ''} {commission.value}
                        </div>
                        <div className='timeline-badge'>
                          <i className='fa fa-genderless text-warning fs-1'></i>
                        </div>
                        <div className='fw-mormal timeline-content text-muted ps-3'>
                          {commission.change_date.toString().substr(6,2) + '/' + commission.change_date.toString().substr(4,2) + '/' + commission.change_date.toString().substr(0,4)}
                        </div>
                      </div>
                    )}

                  </div>
                </>}


              </div>
              {/* end: Card Body */}
            </div>
          </div>

          <hr/>
          <Link to='/change-password'>
            <button type='button' className='btn btn-success mx-5'>
              Đổi mật khẩu
            </button>
          </Link>
          <hr/>
          <button type='button' className='btn btn-primary' onClick={() => {
            dispatch(auth.actions.logout())
          }}>
            Đăng xuất
          </button>
          {/*table account quantity*/}
          {quantityDateArr.length > 0 && (
            <>
              <hr/>
              <div className='card-body py-3'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                  {/* begin::Table */}
                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    {/* begin::Table head */}
                    <thead>
                    <tr className='fw-bolder text-muted'>
                      <th className='min-w-150px'>Ngày</th>
                      <th className='min-w-120px'>Số lượng tài khoản kích hoạt</th>
                    </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                    {quantityDateArr.reverse().map((quantityDate: any) => {
                      return (
                        <tr key={quantityDate.date}>
                          <td>
                            {quantityDate.date.substr(6,2) + '/' + quantityDate.date.substr(4,2) + '/' + quantityDate.date.substr(0,4)}
                          </td>
                          <td>
                            {quantityDate.quantity ?? ''}
                          </td>
                        </tr>
                      )
                    })}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                  {/* end::Table */}
                </div>
                {/* end::Table container */}
              </div>
            </>
          )}

          {/*table account quantity*/}
        </div>
      </div>
      {/* end::Row */}
    </>
  )

}
const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
