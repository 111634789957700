import React, {useEffect} from 'react'
import {shallowEqual, useSelector, useDispatch} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link} from "react-router-dom";
import {MenuTwo} from "../components/MenuTwo";

const CustomerStudyEnglish = () => {
  const dispatch = useDispatch()

  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const currentUser = useSelector(({auth}) => auth.user, shallowEqual)
  // báo cáo người lớn - trẻ con
  const isChild = currentUser.user.is_child;

  // đã trả tiền được đại lý duyệt
  const isActive = currentUser.user.is_active;

  const userInfo = useSelector(store => store.userInfo)
  const LANGUAGE = userInfo.language

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      {isActive
        ? (
          // da kich hoat
          <>
            <div style={{ backgroundColor: '#333', height: '100%'}}>
              <div
                style={{
                  width: WIDTH,
                  height: '100%',
                  margin: 'auto',
                  backgroundColor: '#fff'
                }}
              >

                <div
                  style={{
                    width: '100%',
                    height: '100px',
                    paddingTop: '30px',
                    paddingLeft: '20px',
                    backgroundColor: '#2e84e0',
                    paddingRight: '20px',
                    justifyContent: 'space-between',
                    display: 'flex'
                  }}
                >
                  <h1 style={{
                    color: 'white',
                    fontFamily: 'Linotte-Heavy',
                    fontSize: '24px'
                  }}>
                    {LANGUAGE === 'VI'
                      ? 'Phát âm tiếng Anh'
                      : 'English pronunciation'
                    }

                  </h1>
                  <div>
                    <h1
                      onClick={() => dispatch({
                        type: 'SET_LANGUAGE',
                        payload: {
                          language: 'VI'
                        }
                      })}
                      style={{color: LANGUAGE === 'VI' ? 'white' : '#ccc', display: 'inline-block'}}>
                      VIE
                    </h1>
                    <h1 style={{color: '#ccc', display: 'inline-block', marginLeft: '10px', marginRight: '10px'}}>
                      |
                    </h1>
                    <h1
                      onClick={() => dispatch({
                        type: 'SET_LANGUAGE',
                        payload: {
                          language: 'EN'
                        }
                      })}
                      style={{color: LANGUAGE === 'EN' ? 'white' : '#ccc', display: 'inline-block'}}>
                      ENG
                    </h1>
                  </div>
                </div>

                <div
                  style={{
                    marginTop: '-50px',
                    textAlign: 'center'
                  }}
                >
                  <img style={{ width: '100%', height: 'auto', padding: '15px', borderRadius: '30px' }}
                       src={toAbsoluteUrl('/media/logos/english-back.jpg')}
                       alt=""
                  />
                  <div style={{ padding: '10px 40px', textAlign: 'center' }}>
                    <h4 style={{ color: '#01a1ff', lineHeight: '1.8' }} >"
                      {LANGUAGE === 'VI'
                        ? 'Tri thức dẫn lối thành công'
                        : 'Knowledge leads the way to success'
                      }

                      "</h4>
                  </div>
                </div>

                {/*bài học 1*/}
                <a
                  href="https://youtu.be/9msl1u6ZSU0?si=Bz9U0_2Hbze8EUp1"
                  target='_blank'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 1: Giới thiệu khóa âm'
                        : 'Lesson 1: Introducing English pronunciation'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 2*/}
                <a
                  href="https://youtu.be/F10qgQgu3Y0?si=-4AdWQohB8oOGD2l"
                  target='_blank'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 2: Âm i dài và ngắn"'
                        : 'Lesson 2: The i sound is long and short'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 3*/}
                <a
                  href="#"
                  target='_top'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                      backgroundColor: '#ccc'
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 3: Âm u dài và ʊ ngắn'
                        : 'Lesson 3: U is long and ʊ is short'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 4*/}
                <a
                  href="#"
                  target='_top'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                      backgroundColor: '#ccc'
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 4: Âm e và âm æ'
                        : 'Lesson 4: The e sound and the æ sound'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 5*/}
                <a
                  href="#"
                  target='_top'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                      backgroundColor: '#ccc'
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 5: Âm ə và ɜ'
                        : 'Lesson 5: Sounds ə and ɜ'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 6*/}
                <a
                  href="#"
                  target='_top'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                      backgroundColor: '#ccc'
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 6: Âm ʌ and ɑː'
                        : 'Lesson 6: Sound ʌ and ɑː'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 7*/}
                <a
                  href="#"
                  target='_top'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                      backgroundColor: '#ccc'
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 7: Âm ɔː và ɒ'
                        : 'Lesson 7: Sound ɔː và ɒ'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 8*/}
                <a
                  href="#"
                  target='_top'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                      backgroundColor: '#ccc'
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 8: Âm ɪə and eə'
                        : 'Lesson 8: Sound ɪə and eə'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 9*/}
                <a
                  href="#"
                  target='_top'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                      backgroundColor: '#ccc'
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 9: Âm ʊə và əʊ'
                        : 'Lesson 9: Sound ʊə and əʊ'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 10*/}
                <a
                  href="#"
                  target='_top'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                      backgroundColor: '#ccc'
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 10: Âm aʊ và eɪ'
                        : 'Lesson 10: Sound aʊ and eɪ'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 11*/}
                <a
                  href="#"
                  target='_top'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                      backgroundColor: '#ccc'
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 11: Âm aɪ và ɔɪ'
                        : 'Lesson 11: Sound aɪ and ɔɪ'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 12*/}
                <a
                  href="#"
                  target='_top'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                      backgroundColor: '#ccc'
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 12: Cặp phụ âm p và b'
                        : 'Lesson 12: Consonant pair p and b'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 13*/}
                <a
                  href="#"
                  target='_top'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                      backgroundColor: '#ccc'
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 13: Cặp phụ âm f và v'
                        : 'Lesson 13: Consonant pair f and v'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 14*/}
                <a
                  href="#"
                  target='_top'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                      backgroundColor: '#ccc'
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 14: Cặp phụ âm t và d'
                        : 'Lesson 14: Consonant pair t and d'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 15*/}
                <a
                  href="#"
                  target='_top'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                      backgroundColor: '#ccc'
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 15: Cặp phụ âm tʃ – dʒ'
                        : 'Lesson 15: Consonant pair tʃ and dʒ'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 16*/}
                <a
                  href="#"
                  target='_top'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                      backgroundColor: '#ccc'
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 16: Âm k và g'
                        : 'Lesson 16: Sound k and g'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 17*/}
                <a
                  href="#"
                  target='_top'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                      backgroundColor: '#ccc'
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 17: Âm s và z'
                        : 'Lesson 17: Sound s and z'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 18*/}
                <a
                  href="#"
                  target='_top'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                      backgroundColor: '#ccc'
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 18: Cặp âm ʃ and ʒ'
                        : 'Lesson 18: The pair of sounds ʃ and ʒ'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 19*/}
                <a
                  href="#"
                  target='_top'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                      backgroundColor: '#ccc'
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 19: Âm m và n'
                        : 'Lesson 19: Sound m and n'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 20*/}
                <a
                  href="#"
                  target='_top'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                      backgroundColor: '#ccc'
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 20: Âm ŋ và h'
                        : 'Lesson 20: Sound ŋ and h'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 21*/}
                <a
                  href="#"
                  target='_top'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                      backgroundColor: '#ccc'
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 21: Âm w và j'
                        : 'Lesson 21: Sound w and j'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 22*/}
                <a
                  href="#"
                  target='_top'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                      backgroundColor: '#ccc'
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 22: Âm r và l'
                        : 'Lesson 22: Sound r and l'
                      }

                    </h3>
                  </div>
                </a>

                {/*bài học 23*/}
                <a
                  href="#"
                  target='_top'
                >
                  <div
                    style={{
                      margin: '20px 15px',
                      padding: '15px',
                      position: 'relative',
                      border: '2px solid #dddddd',
                      borderRadius: '10px',
                      boxShadow: '0px 4px #dddddd',
                      backgroundColor: '#ccc'
                    }}
                  >
                    <h3
                      style={{
                        fontFamily: 'Linotte-Heavy'
                      }}
                    >
                      {LANGUAGE === 'VI'
                        ? 'Bài học 23: Cặp phụ âm θ và ð'
                        : 'Lesson 23: Consonant pairs θ and ð'
                      }

                    </h3>
                  </div>
                </a>

                <MenuTwo active='study'/>
              </div>

            </div>
          </>
        )
        : (
          // chua kich hoat
          <>
            <div style={{ backgroundColor: '#333', height: '100%'}}>
              <div
                style={{
                  width: WIDTH,
                  height: '100%',
                  margin: 'auto',
                  backgroundColor: '#fff'
                }}
              >
                <img style={{ width: '100%', height: window.innerHeight - 70}} src={toAbsoluteUrl('/media/logos/inactive-study3.jpg')} alt=""/>

                <MenuTwo active='study'/>
              </div>

            </div>
          </>
        )
      }
    </>
  )

}

export {CustomerStudyEnglish}
